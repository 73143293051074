import { Button } from "@mui/material"
import { useRouter } from "next/router"
import { FC, useEffect, useState } from "react"
import { getQueryStringParams } from "../../../helpers/strings/getQueryStringParams"
import Pill from "../../Pill"

type Option = {
  title: string
  value: string
  icon: JSX.Element
}

type Props = {
  options: Option[]
  queryStringParameter: string
  filter: string
  setFilter: (filter: unknown) => void
  defaultFilterValue: string
}

export const FilterGroupWithQueryStringParam: FC<Props> = ({
  options,
  queryStringParameter,
  filter,
  setFilter,
  defaultFilterValue,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(filter)
  const router = useRouter()

  useEffect(() => {
    const { [queryStringParameter]: value } = getQueryStringParams()

    if (value) {
      setFilter(value)
    } else {
      setFilter(defaultFilterValue)
    }
  }, [queryStringParameter, setFilter, defaultFilterValue])

  return (
    <div className="flex gap-x-1 overflow-x-auto">
      {options.map((option) => (
        <div key={option.value} className="relative">
          <Button
            disableRipple
            onClick={() => {
              if (window.location.pathname) {
                const params = getQueryStringParams()

                router.replace(
                  {
                    pathname: window.location.pathname,
                    query: { ...params, [queryStringParameter]: option.value },
                  },
                  undefined,
                  { shallow: true }
                )
              }

              setFilter(option.value)
              setSelectedOption(option.value)
            }}
            className="px-1 uppercase"
          >
            <Pill
              color={option.value === selectedOption ? "black" : "gray"}
              className="text-sm flex flex-row gap-2 min-w-fit items-center"
            >
              {option.icon}
              <span className={option.value === selectedOption ? "text-white" : "text-gray-500"}>{option.title}</span>
            </Pill>
          </Button>
        </div>
      ))}
    </div>
  )
}
