import { Box, Checkbox, Divider, LinearProgress, Stack, colors } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { FC, useContext, useMemo, useState } from "react"
import { BiCheck, BiPlus, BiSortAlt2, BiTrash, BiX } from "react-icons/bi"
import { useQuery } from "urql"
import * as Yup from "yup"
import { Formik } from "formik"
import { DivisionBadge } from "../components/DivisionBadge"
import { QuickMenuMui } from "../components/QuickMenuMui"
import {
  UserAssetRetainmentOption,
  TeamDetailsPageGetUserQuery,
  useGrantDivisionAccessToUserMutation,
  useRevokeDivisionAccessFromUserMutation,
  useSwitchDivisionAssignmentForUserMutation,
} from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { useHandleError } from "../hooks/useHandleError"
import { PickPlus } from "../types/helpers"
import { MuiModal } from "./Modals/components/Elements/MuiModal"
import { ModalProps, useModalProps } from "./Modals/hooks/useModalProps"
import { SectionHeader } from "./PageSectionHeader"
import { QuickMenuDotsHorizontal } from "./QuickMenu/QuickMenuDotsHorizontal"
import { EmptyStateBlock } from "./Table/EmptyStateBlock"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { DivisionSelect, DivisionSelectionExpectation } from "./Formik/DivisionSelect"
import { UserExpectation } from "./Partials/User/ReassignUserTaskForm/ReassignUserTaskForm"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { ProjectAndTasksMultiSelects } from "./Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { RadioGroup } from "./Formik/RadioGroup"
import { DrawerContext } from "./Partials/Drawer/providers/DrawerProvider"
import { useCurrentUser } from "../providers/PermissionsProvider/currentUserProvider"

const AllOrganizationDivisionsDocument = graphql(`
  query AllOrganizationDivisions {
    myOrganization {
      id
      name
      imageUrl
      isCurrentAssignment
      isPrimary
      divisions {
        id
        name
        imageUrl
        isCurrentAssignment
        isPrimary
      }
    }
  }
`)

type Props = {
  assignedDivision?: PickPlus<TeamDetailsPageGetUserQuery["user"]["assignedDivision"], "id" | "name"> | null
  divisionAccess?: PickPlus<TeamDetailsPageGetUserQuery["user"]["divisionAccess"][number], "id" | "name">[]
  isAdmin: boolean
  title?: string
  user: UserExpectation
}

export const UserDivisionsTable = ({ assignedDivision, divisionAccess, isAdmin, title, user }: Props) => {
  const { clearAll } = useContext(DrawerContext)

  const [{ data, error }] = useQuery({ query: AllOrganizationDivisionsDocument })
  const [divisionIdAccessTarget, setDivisionIdAccessTarget] = useState<string | null>(null)

  const switchDivisionAccessModalProps = useModalProps()
  const [, switchDivisionAssignmentForUserMutation] = useSwitchDivisionAssignmentForUserMutation()

  const grantDivisionAccessModalProps = useModalProps()
  const [, grantDivisionAccessToUserMutation] = useGrantDivisionAccessToUserMutation()

  const removeDivisionAccessModalProps = useModalProps()
  const [, revokeDivisionAccessFromUser] = useRevokeDivisionAccessFromUserMutation()

  const { hasGrantedPermission } = useContext(PermissionsContext)
  const canUpdatePermissionsInBulk = hasGrantedPermission("user:assign:*")

  useHandleError(error, "There was an error fetching divisions.")

  const organizationId = data?.myOrganization?.id ?? ""

  const userDivisionAssignment = useMemo(() => {
    if (!data?.myOrganization) return []

    const { divisions, ...organization } = data?.myOrganization || {}

    const sortedDivisions = divisions.sort((a, b) => (a?.name || "").localeCompare(b?.name || ""))

    return [{ ...organization, organizationId: organization.id }, ...sortedDivisions].map((division) => {
      const isCurrentAssignment =
        // Assigned to specific division
        division.id === assignedDivision?.id ||
        // No assignment defined, default to main org division
        (!assignedDivision?.id && division.isPrimary)
      return {
        ...division,
        isCurrentAssignment,
        divisionAccess:
          isCurrentAssignment ||
          divisionAccess?.some(
            (access) =>
              // Specific division access
              access.id === division.id ||
              // Main organization access defined
              (access?.id === null && division.isPrimary)
          ),
      }
    })
  }, [data, assignedDivision, divisionAccess])

  const hasOrgDivisions = userDivisionAssignment?.length > 1
  const isAssignedToPartialDivisions = hasOrgDivisions && divisionAccess?.length !== userDivisionAssignment.length

  if (!hasOrgDivisions) return null

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Division",
      flex: 3,
      renderCell: ({ row }) => <DivisionBadge division={row} noHover showDivisionTypeLabel />,
    },
    {
      field: "isCurrentAssignment",
      headerName: "Current Division",
      renderCell: ({ row }) =>
        row.isCurrentAssignment ? (
          <BiCheck className="text-blue-600" aria-label={`Assigned to ${row.name}`} />
        ) : (
          <BiX className="text-gray-400" aria-label={`Not assigned to ${row.name}`} />
        ),
    },
    {
      field: "divisionAccess",
      headerName: "Extra Access",
      renderCell: ({ row }) =>
        row.divisionAccess ? (
          <BiCheck className="text-blue-600" aria-label={`Has access to ${row.name}`} />
        ) : (
          <BiX className="text-gray-400" aria-label={`No access to ${row.name}`} />
        ),
    },
    {
      field: "Actions",
      type: "actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: ({ row }: GridRenderCellParams) =>
        row.isCurrentAssignment ? null : (
          <QuickMenuMui
            items={[
              [
                {
                  value: "Switch to division",
                  Icon: BiSortAlt2,
                  isDisabled: row.isCurrentAssignment,
                  onClick: () => {
                    setDivisionIdAccessTarget(row.id)
                    switchDivisionAccessModalProps.handleOpenModal()
                  },
                },
                {
                  value: "Add division access",
                  Icon: BiPlus,
                  isDisabled: !isAdmin || row.divisionAccess,
                  onClick: () => {
                    setDivisionIdAccessTarget(row.id)
                    grantDivisionAccessModalProps.handleOpenModal()
                  },
                },
              ],
              [
                {
                  value: "Remove access",
                  Icon: BiTrash,
                  isDisabled: row.isCurrentAssignment || !row.divisionAccess,
                  onClick: () => {
                    setDivisionIdAccessTarget(row.id)
                    removeDivisionAccessModalProps.handleOpenModal()
                  },
                  color: "red",
                  iconStyles: "text-red-500 size-5",
                },
              ],
            ]}
          >
            <QuickMenuDotsHorizontal />
          </QuickMenuMui>
        ),
    },
  ]

  return (
    <Box>
      {title && (
        <SectionHeader
          hideBorder
          title={title}
          actionText={isAdmin && isAssignedToPartialDivisions ? "Add Division Access" : undefined}
          onClick={isAdmin && isAssignedToPartialDivisions ? grantDivisionAccessModalProps.handleOpenModal : undefined}
          permissions={canUpdatePermissionsInBulk ? "user:update" : undefined}
        />
      )}

      <Box className="border-t-0" sx={{ height: "auto", width: "100%", marginTop: "1rem" }}>
        <DataGridPro
          autoHeight
          columns={columns.map((col) => ({
            flex: 1,
            disableReorder: true,
            pinningConfiguration: { pinLeft: false },
            ...col,
          }))}
          disableRowSelectionOnClick
          getRowHeight={() => "auto"}
          rows={userDivisionAssignment}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => <EmptyStateBlock label="No divisions found" />,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={{
            border: `1px solid ${colors.grey[300]}`,
            "& .MuiDataGrid-cell:focus": { outline: "none" },
            "& .MuiDataGrid-cell:focus-within": { outline: "none" },
            "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
            "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
            "& .MuiDataGrid-cell": { border: 0 },
          }}
        />
      </Box>

      {grantDivisionAccessModalProps.isOpen && (
        <GrantDivisionAccessModal
          divisions={userDivisionAssignment.filter((division) => !division.divisionAccess)}
          selectedDivisionId={divisionIdAccessTarget ?? null}
          modalProps={grantDivisionAccessModalProps}
          handleFormSubmit={async (values) => {
            const { divisionIds } = values || {}
            const grantPrimary = divisionIds?.includes(organizationId)
            const divisionIdsToGrant = divisionIds?.filter((divisionId) => divisionId !== organizationId)
            const result = await grantDivisionAccessToUserMutation({
              userId: user.id,
              divisionIds: divisionIdsToGrant ?? [],
              grantPrimary,
              organizationId,
            })

            if (result.error) {
              console.error(result.error)
              errorSnack("Failed to grant division access")
            } else {
              successSnack("Division access granted")
            }
            grantDivisionAccessModalProps.handleCloseModal()
          }}
        />
      )}

      {divisionIdAccessTarget && removeDivisionAccessModalProps.isOpen && (
        <RemoveDivisionAccessModal
          modalProps={removeDivisionAccessModalProps}
          divisionId={divisionIdAccessTarget}
          handleFormSubmit={async (values) => {
            if (!divisionIdAccessTarget) return

            const { divisionId } = values || {}
            const revokePrimary = divisionId === organizationId

            const result = await revokeDivisionAccessFromUser({
              userId: user.id,
              divisionId: divisionId ?? "",
              revokePrimary,
              organizationId,
            })

            if (result.error) {
              console.error(result.error)
              errorSnack("Failed to revoke division access")
            } else {
              successSnack("Division access revoked")
            }
            removeDivisionAccessModalProps.handleCloseModal()

            setDivisionIdAccessTarget(null)
          }}
        />
      )}

      {divisionIdAccessTarget && switchDivisionAccessModalProps.isOpen && (
        <SwitchDivisionAssignmentModal
          organizationId={organizationId}
          divisions={userDivisionAssignment}
          divisionId={divisionIdAccessTarget}
          user={user}
          isAdmin={isAdmin}
          modalProps={switchDivisionAccessModalProps}
          handleFormSubmit={async (values) => {
            if (!divisionIdAccessTarget) return

            const {
              divisionId,
              assignment: {
                selectedProjectId: [projectId],
                selectedTaskId,
              },
              assetRetainment,
              maintainDivisionAccess,
            } = values || {}

            const result = await switchDivisionAssignmentForUserMutation({
              userId: user.id,
              organizationId,
              divisionId,
              projectId: projectId,
              taskId: selectedTaskId,
              assetRetainment,
              maintainDivisionAccess,
            })

            if (result.error) {
              console.error(result.error)
              errorSnack("Failed to switch division assignment")
            } else {
              successSnack("Division assignment successfully switched")
            }
            switchDivisionAccessModalProps.handleCloseModal()

            setDivisionIdAccessTarget(null)
            clearAll()
          }}
        />
      )}
    </Box>
  )
}

type DivisionAccessValues = {
  divisionId?: string
  divisionIds?: string[]
}

type GrantDivisionAccessModalType = {
  divisions: DivisionSelectionExpectation[]
  selectedDivisionId: string | null
  handleFormSubmit: (values?: DivisionAccessValues) => void
  modalProps: ModalProps
}

const GrantDivisionAccessModal: FC<GrantDivisionAccessModalType> = ({
  divisions,
  selectedDivisionId,
  handleFormSubmit,
  modalProps,
}) => {
  // exclude the current assignment from the list of divisions
  const filteredDivisions = divisions.filter((division) => !division.isCurrentAssignment && !division.divisionAccess)
  return (
    <Formik<DivisionAccessValues>
      enableReinitialize
      initialValues={{ divisionIds: selectedDivisionId ? [selectedDivisionId] : [] }}
      validationSchema={Yup.object().shape({
        divisionIds: Yup.array().of(Yup.string().required("Divisions are required")),
      })}
      onSubmit={handleFormSubmit}
    >
      {({ submitForm, resetForm }) => (
        <MuiModal
          variant="small"
          isOpen={modalProps.isOpen}
          contentLabel="Add Division Access"
          handleCloseModal={() => {
            modalProps.handleCloseModal()
            resetForm()
          }}
          submitButtonText="Confirm"
          submitForm={submitForm}
          submitButtonColor="primary"
          isLoading={false}
        >
          <div className="flex flex-col gap-4 min-h-[140px] items-start">
            <p>
              By granting the user access to another division, they can now switch between this division and any others
              to which they have access.
            </p>

            <DivisionSelect
              multiple
              divisions={filteredDivisions}
              preselected={selectedDivisionId ? [{ value: selectedDivisionId, label: selectedDivisionId }] : undefined}
              name="divisionIds"
              label="Division"
            />
          </div>
        </MuiModal>
      )}
    </Formik>
  )
}

type RevokeDivisionAccessModalType = {
  divisionId: string | null
  handleFormSubmit: (values?: DivisionAccessValues) => void
  modalProps: ModalProps
}

const RemoveDivisionAccessModal: FC<RevokeDivisionAccessModalType> = ({ divisionId, handleFormSubmit, modalProps }) => {
  return (
    <MuiModal
      variant="small"
      isOpen={modalProps.isOpen}
      contentLabel="Remove Division Access"
      handleCloseModal={modalProps.handleCloseModal}
      submitButtonText="Remove Division Access"
      submitForm={() => handleFormSubmit({ divisionId: divisionId ?? "" })}
      isLoading={false}
      submitButtonColor="error"
    >
      <div className="flex flex-col gap-4 min-h-[140px] items-start">
        <p>
          Are you sure you want to remove this user&apos;s access to this division? If you proceed, the user will no
          longer be able to switch themselves to this division.
        </p>
      </div>
    </MuiModal>
  )
}

type DivisionAssignmentValues = {
  divisionId: string | null
  assignment: {
    selectedProjectId: string[]
    selectedTaskId: string
  }
  assetRetainment: UserAssetRetainmentOption
  maintainDivisionAccess: boolean
}

type SwitchDivisionAssignmentModalType = {
  divisionId: string
  divisions: DivisionSelectionExpectation[]
  handleFormSubmit: (assignment: DivisionAssignmentValues) => void
  isAdmin: boolean
  modalProps: ModalProps
  organizationId: string
  user: UserExpectation
}

const SwitchDivisionAssignmentModal: FC<SwitchDivisionAssignmentModalType> = ({
  divisionId,
  divisions,
  handleFormSubmit,
  isAdmin,
  modalProps,
  organizationId,
  user,
}) => {
  const currentUser = useCurrentUser()
  const isCurrentUser = user.id === currentUser?.id
  const isCurrentDivision = currentUser?.isCurrentDivision
  const maintainDivisionAccess = isCurrentUser && isCurrentDivision
  return (
    <Formik<DivisionAssignmentValues>
      enableReinitialize
      initialValues={{
        divisionId: divisionId || "",
        assignment: {
          selectedProjectId: [],
          selectedTaskId: "",
        },
        assetRetainment: UserAssetRetainmentOption.Offload,
        maintainDivisionAccess: maintainDivisionAccess || false,
      }}
      validationSchema={Yup.object().shape({
        divisionId: Yup.string().required("Division is required"),
        assignment: Yup.object().shape({
          selectedProjectId: Yup.array().length(1).of(Yup.string().required("Project is required")),
          selectedTaskId: Yup.string().required("Task is required"),
        }),
        assetRetainment: Yup.mixed().oneOf([UserAssetRetainmentOption.Offload, UserAssetRetainmentOption.Retain]),
        maintainDivisionAccess: Yup.boolean().required("Maintain division access is required"),
      })}
      onSubmit={handleFormSubmit}
    >
      {({ submitForm, isSubmitting, resetForm, values, setFieldValue }) => (
        <MuiModal
          isOpen={modalProps.isOpen}
          contentLabel="Switch Division"
          handleCloseModal={() => {
            modalProps.handleCloseModal()
            resetForm()
          }}
          submitButtonText="Confirm"
          submitForm={submitForm}
          submitButtonColor="primary"
          disabled={isSubmitting}
          isLoading={false}
        >
          <Stack rowGap="xl">
            <p className="mb-8">
              To transfer the user to a new division, reassignment to a new project or task is required. The user&apos;s
              equipment can be offloaded or retained.
            </p>
            <DivisionSelect
              divisions={divisions.filter((division) => !division.isCurrentAssignment)}
              preselected={[{ value: divisionId, label: divisionId }]}
              name="divisionId"
              label="Division"
              className="w-full mb-0"
            />
            <ProjectAndTasksMultiSelects
              disabled={!values.divisionId}
              divisionId={divisionId ?? organizationId}
              formGroupId="assignment"
              users={[user]}
            />
            <div className="flex items-center gap-3 my-4 ml-2">
              <RadioGroup
                name="assetRetainment"
                options={[
                  {
                    label: "Offload assets",
                    value: UserAssetRetainmentOption.Offload,
                  },
                  {
                    label: "Retain assets",
                    value: UserAssetRetainmentOption.Retain,
                  },
                ]}
              />
            </div>
            {isAdmin && (
              <>
                <Divider className="mb-8" />
                <p className="mb-5">
                  You can grant the user access to their current division, allowing them to freely move between
                  divisions if desired.
                </p>
                <label className="flex gap-1 items-center mt-2">
                  <Checkbox
                    disabled={maintainDivisionAccess}
                    checked={!!values.maintainDivisionAccess}
                    onChange={() => setFieldValue("maintainDivisionAccess", !values.maintainDivisionAccess)}
                  />
                  Maintain current user division access
                </label>
              </>
            )}
          </Stack>
        </MuiModal>
      )}
    </Formik>
  )
}
