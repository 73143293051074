import { Box } from "@mui/material"
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro"
import { FC, useEffect } from "react"
import { BiEditAlt } from "react-icons/bi"
import { getTimeEntryArrayTotalSeconds } from "../../../../../helpers/timeEntries/getTimeEntryArrayTotalSeconds"
import { getTimeEntryDateBookends } from "../../../../../helpers/timeEntries/getTimeEntryDateBookends"
import { secondsToFormattedString } from "../../../../../helpers/dateAndTime/time-utility"
import { QuickMenu } from "../../../../QuickMenu"
import { QuickMenuDotsHorizontal } from "../../../../QuickMenu/QuickMenuDotsHorizontal"
import { CustomDetailPanelToggle } from "../../../DataGrid/CustomDetailPanelToggle"
import { TimeEntryWithProjectAndTaskName } from "../../EditDayActivityForm/EditDayActivityForm"
import { TimeEntryActivityBar } from "../../TimeEntryActivityBar"
import { ClockInOutCell } from "./ClockInOutCell"
import { DailyDetailPanel } from "./DailyDetailPanel"
import { defaultTimeEntryColumnProps } from "./TimeEntryDaysDataGrid"

type TimeEntryDay = {
  id: string
  day: string
  activity: { minDate: Date; maxDate: Date }
  clockIn: Date
  clockOut?: Date | null | undefined
  totalDurationInSeconds: number
  timeEntries: TimeEntryWithProjectAndTaskName[]
}

export const WeeklyDetailPanel: FC<{
  timeEntries: Record<string, TimeEntryWithProjectAndTaskName[]>
  openEditDayActivityModal: (timeEntries: TimeEntryWithProjectAndTaskName[]) => void
  handleScroll: GridEventListener<"scrollPositionChange">
}> = ({ timeEntries, openEditDayActivityModal, handleScroll }) => {
  const apiRef = useGridApiRef()

  useEffect(() => {
    apiRef?.current?.subscribeEvent?.("scrollPositionChange", handleScroll)
  }, [apiRef, handleScroll])

  const columns: GridColDef[] = [
    {
      ...defaultTimeEntryColumnProps,
      field: "day",
      headerName: "Date",
      minWidth: 180,
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryDay>) => row.day,
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "activity",
      headerName: "Activity",
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDay>) => (
        <TimeEntryActivityBar
          timeEntries={row.timeEntries}
          minDate={row.activity.minDate}
          maxDate={row.activity.maxDate}
        />
      ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "clockIn",
      headerName: "Clock in",
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDay>) => (
        <ClockInOutCell clockTime={row.clockIn} photoUrl={row.timeEntries?.[0]?.signInPhotoUrl} />
      ),
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "clockOut",
      headerName: "Clock out",
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDay>) =>
        row.clockOut ? (
          <ClockInOutCell
            clockTime={row.clockOut}
            photoUrl={row.timeEntries[row.timeEntries.length - 1].signOutPhotoUrl}
          />
        ) : null,
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "total",
      headerName: "Total",
      minWidth: 100,
      valueGetter: ({ row }: GridRenderCellParams<TimeEntryDay>) => {
        return secondsToFormattedString(row.totalDurationInSeconds)
      },
    },
    {
      ...defaultTimeEntryColumnProps,
      field: "quickActionsMenu",
      headerName: "",
      flex: 0,
      width: 64,
      resizable: false,
      renderCell: ({ row }: GridRenderCellParams<TimeEntryDay>) => (
        <QuickMenu
          items={[
            [
              {
                requiredPermission: "timeEntry:update",
                value: "Edit day activity",
                onClick: () => openEditDayActivityModal(row.timeEntries!),
                Icon: BiEditAlt,
              },
            ],
          ]}
        >
          <QuickMenuDotsHorizontal />
        </QuickMenu>
      ),
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      width: 64,
      resizable: false,
      renderCell: ({ id, value }) => <CustomDetailPanelToggle id={id} value={value} />,
    },
  ]

  const days = Object.keys(timeEntries)?.map((day) => {
    const entries = [...timeEntries[day]]?.sort(
      (a, b) => new Date(a?.startAt)?.valueOf() - new Date(b?.startAt)?.valueOf()
    )
    const clockIn = entries?.[0]?.startAt
    const clockOut = entries?.[entries.length - 1]?.endAt
    const [startOfDay, endOfDay] = getTimeEntryDateBookends(entries)
    const totalDuration = getTimeEntryArrayTotalSeconds(entries)

    const dailyEntries: TimeEntryDay = {
      id: day,
      day,
      activity: {
        minDate: startOfDay,
        maxDate: endOfDay,
      },
      clockIn,
      clockOut,
      totalDurationInSeconds: totalDuration,
      timeEntries: entries,
    }

    return dailyEntries
  })

  return (
    <Box paddingBottom={2}>
      <DataGridPro
        apiRef={apiRef}
        columns={columns}
        getDetailPanelContent={(params) => getDetailPanelContent(params, openEditDayActivityModal)}
        getDetailPanelHeight={getDetailPanelHeight}
        initialState={{
          pinnedColumns: { right: [GRID_DETAIL_PANEL_TOGGLE_FIELD] },
        }}
        rows={days}
        hideFooter
        sx={{
          border: "none",
          fontSize: 16,
          "& .MuiDataGrid-columnHeaders": { borderBottom: "1px solid gray-100" },
          "& .MuiDataGrid-cell:focus": { outline: "none" },
          "& .MuiDataGrid-cell:focus-within": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
          "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
          "& .MuiDataGrid-pinnedColumnHeaders": { display: "none" },
          "& .MuiDataGrid-pinnedColumns": { boxShadow: "none" },
        }}
      />
    </Box>
  )
}

const getDetailPanelContent = (
  params: GridRowParams,
  openEditDayActivityModal: (timeEntries: TimeEntryWithProjectAndTaskName[]) => void
) => <DailyDetailPanel timeEntries={params.row.timeEntries} openEditDayActivityModal={openEditDayActivityModal} />

const getDetailPanelHeight = (_params: GridRowParams<TimeEntryWithProjectAndTaskName>) => "auto" as const
