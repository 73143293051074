import { Typography } from "@mui/material"
import { format } from "date-fns"
import { FC } from "react"
import { BiBlock } from "react-icons/bi"

type Props = {
  clockTime: Date | null | undefined
  originalClockTime?: Date | null
  photoUrl?: string | null
}

export const ClockInOutCell: FC<Props> = ({ clockTime, originalClockTime, photoUrl }) => {
  const showOriginalClockTime = originalClockTime && originalClockTime.getTime() !== clockTime?.getTime()

  return (
    <div className="flex gap-2 items-center">
      {photoUrl ? (
        <img className="size-6 rounded" src={photoUrl} alt="time entry photo" />
      ) : (
        <div className="flex justify-center items-center size-6 rounded bg-gray-800 text-white">
          <BiBlock className="size-4 text-white" />
        </div>
      )}
      <div>
        <Typography className="mb-[-5px]">{!!clockTime ? format(clockTime, "h:mm aaa") : ""}</Typography>
        {showOriginalClockTime && (
          <Typography className="line-through text-gray-500 text-sm">
            {originalClockTime ? format(originalClockTime, "h:mm aaa") : ""}
          </Typography>
        )}
      </div>
    </div>
  )
}
