import React, { FC } from "react"
import { Typography } from "@mui/material"
import { secondsToFormattedString } from "../../../../helpers/dateAndTime/time-utility"

export const TotalTimeWithEdits: FC<{
  totalTimeInSeconds: number
  totalEditedTimeInSeconds: number | undefined | null
  showTimeDiff?: boolean
  className?: string
}> = ({ totalTimeInSeconds, totalEditedTimeInSeconds, showTimeDiff = false, className = "flex gap-1" }) => {
  const symbol = (totalEditedTimeInSeconds || 0) > 0 ? "+" : "-"
  return (
    <div className={className}>
      <Typography className="text-gray-600">{secondsToFormattedString(totalTimeInSeconds)}</Typography>
      {!!totalEditedTimeInSeconds && (
        <Typography className="text-gray-500">
          <span className="line-through">
            {secondsToFormattedString(totalEditedTimeInSeconds - totalTimeInSeconds)}
          </span>{" "}
          {showTimeDiff && <>{`(${symbol}${secondsToFormattedString(totalEditedTimeInSeconds)})`}</>}
        </Typography>
      )}
    </div>
  )
}
