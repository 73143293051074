import { Transition } from "@headlessui/react"
import { clsx } from "clsx"
import { FC, useState } from "react"
import { useQuery } from "urql"
import { TaskLayoutPageContent } from "../../../../../pages/projects/[_project_id]/[_task_id]/_layout"
import { TaskAssetsPageContents } from "../../../../../pages/projects/[_project_id]/[_task_id]/assets"
import {
  TaskSummaryDocument,
  TaskSummaryPageContent,
} from "../../../../../pages/projects/[_project_id]/[_task_id]/summary"
import { useCurrentUser } from "../../../../../providers/PermissionsProvider/currentUserProvider"
import { PageTitle } from "../../../../PageTitle"
import { RenderIf } from "../../../../RenderIf"
import { TaskDetails } from "../../../Projects/TaskDetails"
import { TeamAssignments } from "../../../Projects/TeamList/TeamAssignments"
import { DrawerBody } from "../Elements/DrawerBody"
import { DrawerHeader } from "../Elements/DrawerHeader"

type Props = {
  taskId: string
  tab?: TabValue
}

export type TabValue = "details" | "team" | "assets" | "summary"

type Tab = {
  value: TabValue
  label: string
  permission: "task:read" | "task:read-summary"
}

const tabs: Tab[] = [
  {
    value: "details",
    label: "Details",
    permission: "task:read",
  },
  {
    value: "team",
    label: "Team",
    permission: "task:read",
  },
  {
    value: "assets",
    label: "Assets",
    permission: "task:read",
  },
  {
    value: "summary",
    label: "Summary",
    permission: "task:read-summary",
  },
]

export const TaskDrawer: FC<Props> = ({ taskId, tab = "details" }) => {
  const currentUser = useCurrentUser()
  const [currentTab, setCurrentTab] = useState(tab)
  const [{ data: taskData }, refetchTask] = useQuery({
    query: TaskSummaryDocument,
    variables: { id: taskId },
    requestPolicy: "cache-first",
  })
  const task = taskData?.task

  if (!task) {
    return null
  }

  return (
    <>
      <DrawerHeader href={`/projects/${task.projectId}/${task.id}/${currentTab}`} />

      <DrawerBody>
        <TaskLayoutPageContent taskId={task.id} withTabs={false}>
          <div className="flex gap-6 overflow-x-scroll">
            {tabs.map((renderTab) => {
              const isSelected = renderTab.value === currentTab
              return (
                <RenderIf
                  key={renderTab.value}
                  permissionsInclude={renderTab.permission}
                  context={{ projectId: currentUser.projectId! }}
                >
                  <button
                    onClick={() => setCurrentTab(renderTab.value)}
                    className={clsx(
                      "relative my-2 pb-2 font-bold uppercase whitespace-nowrap",
                      "md:my-8",
                      !isSelected && "text-gray-400"
                    )}
                  >
                    {renderTab.label}

                    <Transition
                      appear={true}
                      show={true}
                      enter="transition ease-in-out duration-150 transform"
                      enterFrom="opacity-0 translate-y-full"
                      enterTo="opacity-100 translate-y-0"
                    >
                      <div
                        className={clsx(
                          "w-full relative top-1 h-1 transition-all rounded-sm",
                          isSelected ? "bg-blue-600" : "bg-transparent"
                        )}
                      />
                    </Transition>
                  </button>
                </RenderIf>
              )
            })}
          </div>
          {currentTab === "details" && <TaskDetails task={task} onSuccess={refetchTask} />}
          {currentTab === "team" && (
            <>
              <PageTitle title={`${task?.name || "Task"} teams`} />
              <TeamAssignments project={task.project} task={task} />
            </>
          )}
          {currentTab === "assets" && (
            <>
              <PageTitle title={`${task?.name || "Task"} assets`} />
              <TaskAssetsPageContents />
            </>
          )}
          {currentTab === "summary" && (
            <>
              <PageTitle title={`${task?.name || "Task"} summary`} />
              <TaskSummaryPageContent task={task} />
            </>
          )}
        </TaskLayoutPageContent>
      </DrawerBody>
    </>
  )
}
