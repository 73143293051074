import { Box, Chip, IconButton, LinearProgress } from "@mui/material"
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { clsx } from "clsx"
import Link from "next/link"
import { FC, useContext, useMemo, useState } from "react"
import { BiPlus, BiTrash } from "react-icons/bi"
import {
  Task,
  UserAssignment,
  useBulkUpdateUserAssignmentsMutation,
  useDeleteUserAssignmentMutation,
} from "../graphql/generated/client-types-and-hooks"
import { sortBy } from "../helpers/sorts/sortBy"
import { getFullName } from "../helpers/strings/getFullName"
import { PermissionsContext } from "../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../types/helpers"
import { ChipContainer } from "./Chips/ChipContainer"
import { CreateOrUpdateUserAssignmentForm, UserAssignmentUpdateCandidate } from "./CreateOrUpdateUserAssignmentForm"
import { MuiModal } from "./Modals/components/Elements/MuiModal"
import { ModalProps, useModalProps } from "./Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "./Notistack/ThemedSnackbars"
import { SectionHeader } from "./PageSectionHeader"
import { TaskDrawer } from "./Partials/Drawer/components/Task/TaskDrawer"
import { DrawerContext } from "./Partials/Drawer/providers/DrawerProvider"
import { ProjectBadge } from "./ProjectBadge"
import { QuickMenu } from "./QuickMenu"
import { QuickMenuDotsHorizontal } from "./QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem } from "./QuickMenuMui"
import { EmptyStateBlock } from "./Table/EmptyStateBlock"
import { UserBadge } from "./UserBadge"

type AssignmentExpectation = PickPlus<
  UserAssignment,
  "id" | "projectId" | "taskId" | "userId" | "isCurrentAssignment"
> & {
  project?: PickPlus<UserAssignment["project"], "id" | "name">
  task?: PickPlus<Task, "id" | "name"> | null
  user?: PickPlus<UserAssignment["user"], "id" | "firstName" | "lastName" | "jobTitle" | "isClockedIn">
}

export const UserAssignmentsTable = ({
  assignments,
  preassignments,
  refetch,
  title,
  groupBy,
}: {
  assignments: AssignmentExpectation[]
  preassignments: {
    userId?: string
    taskId?: string
    projectId?: string
  }
  refetch?: () => void
  title?: string
  groupBy: "user" | "project" | "task"
}) => {
  const [editTarget, setEditTarget] = useState<UserAssignmentUpdateCandidate[] | null>(null)

  const { hasGrantedPermission } = useContext(PermissionsContext)
  const canUpdatePermissionsInBulk = hasGrantedPermission("user:assign:*")

  const { push: pushDrawer } = useContext(DrawerContext)

  const [, deleteUserAssignmentMutation] = useDeleteUserAssignmentMutation()
  const [, bulkUpdateUserAssignmentMutation] = useBulkUpdateUserAssignmentsMutation()

  const editAssignmentModalProps = useModalProps("Edit")
  const createAssignmentModalProps = useModalProps("Create")
  const removeLastAssignmentModalProps = useModalProps("Remove Task Access")

  const removeUserTaskAccess = async (assignmentsToCheck: AssignmentExpectation[]) => {
    try {
      await bulkUpdateUserAssignmentMutation({
        assignmentsToDelete: assignmentsToCheck.map((assignment) => assignment.id),
      })
      successSnack("assignment deleted!")
    } catch (error) {
      console.error(error)
      errorSnack("Error deleting assignment")
    }
  }

  const AssignmentChip: FC<{ assignment: AssignmentExpectation; assignmentsCount: number }> = ({
    assignment,
    assignmentsCount,
  }) => {
    // current assignments cannot be deleted here
    // a user must reassign that user to another task
    const onDelete = (assignmentToDelete: AssignmentExpectation) => {
      if (assignmentsCount === 1) {
        setEditTarget([assignmentToDelete])
        removeLastAssignmentModalProps.handleOpenModal()
        return
      }

      deleteUserAssignmentMutation({ id: assignment.id })
    }

    const hasFullProjectAccess = assignment?.task?.name

    return (
      <Chip
        className={clsx(assignment?.task?.id && "hover:underline")}
        color={hasFullProjectAccess ? "default" : "primary"}
        key={assignment.id}
        label={hasFullProjectAccess || "Full Project Task Access"}
        variant="outlined"
        onClick={() => {
          if (!assignment?.task?.id) return
          pushDrawer(<TaskDrawer taskId={assignment?.task?.id} />, "Task")
        }}
        // Prevent the delete icon from rendering if user does not have permission to delete
        {...(canUpdatePermissionsInBulk && { onDelete: () => onDelete(assignment) })}
      />
    )
  }

  const columns: GridColDef[] = [
    {
      headerName: "User",
      field: "userId",
      flex: 1,
      maxWidth: 300,
      renderCell: ({ row }: GridRenderCellParams<UserWithAssignments>) => (
        <Box paddingLeft="0.5rem">{row.user && <UserBadge user={row.user} />}</Box>
      ),
    },
    {
      field: "projectId",
      flex: 1,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<ProjectWithAssignments>) => (
        <Box paddingLeft="0.5rem">
          {params.row.project && (
            <Link href={`/projects/${params.row.project.id}`}>
              <ProjectBadge project={params.row.project} />
            </Link>
          )}
        </Box>
      ),
      headerName: "Project",
    },
    {
      headerName: "Task Access",
      field: "taskAccess",
      flex: 1,
      width: 80,
      renderCell: ({ row }: GridRenderCellParams<UserWithAssignments & ProjectWithAssignments>) => {
        return row.assignments.length > 0 ? (
          <ChipContainer>
            {row.assignments.map((assignment: AssignmentExpectation) => (
              <AssignmentChip key={assignment.id} assignment={assignment} assignmentsCount={row.assignments.length} />
            ))}
            {groupBy !== "task" && (
              <div className="flex justify-center items-center">
                <IconButton
                  sx={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setEditTarget(row.assignments)
                    editAssignmentModalProps.handleOpenModal()
                  }}
                  size="small"
                >
                  <BiPlus />
                </IconButton>
              </div>
            )}
          </ChipContainer>
        ) : null
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: ({ row }: GridRowParams<UserWithAssignments | ProjectWithAssignments>) => {
        const menuItems: MenuItem[][] = [
          [
            {
              value: "Edit Task Access",
              Icon: BiPlus,
              onClick: () => {
                setEditTarget(row.assignments)
                editAssignmentModalProps.handleOpenModal()
              },
            },
          ],
          [
            {
              value: "Remove Task Access",
              Icon: BiTrash,
              color: "red",
              isDisabled: !canUpdatePermissionsInBulk,
              onClick: () => removeUserTaskAccess(row.assignments),
            },
          ],
        ]

        // Edit Task Access should not be available if the groupBy is task
        if (groupBy == "task") menuItems.shift()

        return [
          <QuickMenu
            key="quickMenu"
            buttonShape="round"
            className={clsx(
              "size-10 -mr-4 md:mr-0 flex items-center justify-center flex-none rounded-full transition-colors mt-1",
              "md:size-12",
              "hover:bg-gray-50"
            )}
            disabled={!canUpdatePermissionsInBulk}
            items={menuItems}
          >
            <QuickMenuDotsHorizontal />
          </QuickMenu>,
        ]
      },
    },
  ]

  const rowData = useMemo(() => {
    if (groupBy == "project") return groupAssignmentsByProject(assignments)
    return groupAssignmentsByUser(assignments)
  }, [assignments, groupBy])

  return (
    <Box>
      {title && (
        <SectionHeader
          hideBorder
          title={title}
          onClick={createAssignmentModalProps.handleOpenModal}
          actionText="Add Task Access"
          permissions={canUpdatePermissionsInBulk ? "task:update" : undefined}
        />
      )}

      <Box className="border-t-0" sx={{ height: "auto", width: "100%", marginTop: "1rem" }}>
        <DataGridPro
          autoHeight
          columns={columns}
          disableRowSelectionOnClick={true}
          getEstimatedRowHeight={() => 70}
          getRowHeight={() => "auto"}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                userId: groupBy == "user" || groupBy == "task",
                projectId: groupBy == "project",
                taskAccess: true,
                actions: true,
              },
            },
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          rows={rowData}
          density="comfortable"
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => <EmptyStateBlock label="No project & task access" />,
          }}
          sx={{
            "& .MuiDataGrid-cell:focus": { outline: "none" },
            "& .MuiDataGrid-cell:focus-within": { outline: "none" },
            "& .MuiDataGrid-columnHeader:focus": { outline: "none" },
            "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },
            "& .MuiDataGrid-cell": { border: 0 },
          }}
        />
      </Box>

      {createAssignmentModalProps.isOpen && (
        <CreateOrUpdateUserAssignmentForm
          contextUserAssignments={assignments}
          modalProps={createAssignmentModalProps}
          preassignments={preassignments}
          refetch={refetch}
        />
      )}
      {editAssignmentModalProps.isOpen && (
        <CreateOrUpdateUserAssignmentForm
          contextUserAssignments={assignments}
          modalProps={editAssignmentModalProps}
          preassignments={{ ...preassignments, userId: editTarget?.[0]?.userId }}
          refetch={refetch}
          userAssignments={editTarget}
        />
      )}
      {removeLastAssignmentModalProps.isOpen && (
        <RemoveLastAssignmentModal
          modalProps={removeLastAssignmentModalProps}
          handleEditAccessClick={() => {
            setEditTarget(assignments.filter((assignment) => assignment.userId === editTarget?.at(0)?.userId) || null)
            removeLastAssignmentModalProps.handleCloseModal()
            editAssignmentModalProps.handleOpenModal()
          }}
          handleFormSubmit={(assignment?: AssignmentExpectation | null) => {
            if (!assignment) return

            deleteUserAssignmentMutation({ id: assignment.id })
            if (refetch) refetch()
            removeLastAssignmentModalProps.handleCloseModal()
          }}
          assignment={editTarget?.at(0)}
        />
      )}
    </Box>
  )
}

type RemoveLastAssignmentModalType = {
  assignment?: AssignmentExpectation | null
  handleEditAccessClick: () => void
  handleFormSubmit: (assignment?: AssignmentExpectation | null) => void
  modalProps: ModalProps
}

const RemoveLastAssignmentModal: FC<RemoveLastAssignmentModalType> = ({
  assignment,
  handleEditAccessClick,
  handleFormSubmit,
  modalProps,
}) => {
  return (
    <MuiModal
      isOpen={modalProps.isOpen}
      contentLabel="Remove Task Access"
      handleCloseModal={modalProps.handleCloseModal}
      submitButtonText="Remove Task Access"
      submitForm={() => handleFormSubmit(assignment)}
      isLoading={false}
      submitButtonColor="error"
    >
      <div className="flex flex-col gap-4 min-h-[140px] items-start">
        <p>Are you sure you want to remove {getFullName(assignment?.user)}’s access to this project?</p>
        <p>
          You can also{" "}
          <button className="text-blue-600 underline" type="button" onClick={handleEditAccessClick}>
            edit their access
          </button>{" "}
          if you didn’t mean to remove all access.
        </p>
      </div>
    </MuiModal>
  )
}

type ProjectWithAssignments = {
  id: string
  assignments: AssignmentExpectation[]
  project: AssignmentExpectation["project"]
}

const groupAssignmentsByProject = (assignments: AssignmentExpectation[]) => {
  return assignments.reduce((projectAssignmentGroups, assignment) => {
    // 1. Filter out things we don't want in our list. Including falsy values, missing ids and current assignments
    if (!assignment || !assignment.projectId || assignment.isCurrentAssignment) return projectAssignmentGroups
    const project = projectAssignmentGroups.find((p) => p.id === assignment.projectId)
    if (project) project.assignments.push(assignment)
    else
      projectAssignmentGroups.push({
        id: assignment.projectId!,
        assignments: [assignment],
        project: assignment.project!,
      })

    return projectAssignmentGroups
  }, [] as ProjectWithAssignments[])
}

type UserWithAssignments = {
  id: string
  assignments: AssignmentExpectation[]
  user: AssignmentExpectation["user"]
}

const groupAssignmentsByUser = (assignments: AssignmentExpectation[]) => {
  const userAssignmentGroups = assignments.reduce((assignmentsToGroup, assignment) => {
    // Filter out things we don't want in our list. Including falsy values, missing ids and current assignments
    if (!assignment || !assignment.userId || assignment.isCurrentAssignment) return assignmentsToGroup

    const userAssignmentGroup = assignmentsToGroup.find((u) => u.id === assignment.userId)

    if (userAssignmentGroup) userAssignmentGroup.assignments.push(assignment)
    else assignmentsToGroup.push({ id: assignment.userId, assignments: [assignment], user: assignment.user })

    return assignmentsToGroup
  }, [] as UserWithAssignments[])

  return sortUserAssignmentGroups(userAssignmentGroups)
}

function sortUserAssignmentGroups(userAssignmentGroups: UserWithAssignments[]) {
  return userAssignmentGroups.map((userAssignmentGroup) => {
    return {
      ...userAssignmentGroup,
      assignments: sortBy<AssignmentExpectation>(
        userAssignmentGroup.assignments as AssignmentExpectation[],
        (a: AssignmentExpectation) => a.task?.name?.toLowerCase() ?? ""
      ),
    }
  })
}

// Example output
// [
//   {
//     "id": "user_1",
//     "assignments": [
//       {
//         "assignmentId": "assignment_1",
//         "userId": "user_1",
//         "user": {...}
//       }
//     ]
//   }
// ]
