import { Button } from "@mui/material"
import { clsx } from "clsx"
import Link from "next/link"
import { FC, useContext } from "react"
import { BiLayer, BiStopwatch, BiWifiOff } from "react-icons/bi"
import { User } from "../../../graphql/generated/client-types-and-hooks"
import { useUpdatingUserClockedInHoursAndMinutes } from "../../../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../types/helpers"
import { ClockInOut } from "../../ClockInOut"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { successSnack } from "../../Notistack/ThemedSnackbars"
import { QuickActionContainer } from "../../QuickActionContainer"
import { QuickActionRow } from "../../QuickActionRow"
import { RenderIf } from "../../RenderIf"
import { HoursAndMinutes } from "../../Time/HoursAndMinutes"
import { ButtonFilled, H5 } from "../../deprecated"
import DeprecatedModal from "../../deprecated/StandardModal"
import { DrawerLink } from "../Drawer/components/Elements/DrawerLink"
import { TaskDrawer } from "../Drawer/components/Task/TaskDrawer"
import { UserDrawer } from "../Drawer/components/User/UserDrawer"
import { UserEditDrawer } from "../Drawer/components/User/UserEditDrawer"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { ReassignUserTaskForm } from "./ReassignUserTaskForm/ReassignUserTaskForm"

type Props = {
  user: PickPlus<
    User,
    | "id"
    | "archived"
    | "currentProjectId"
    | "currentTaskId"
    | "firstName"
    | "isClockedIn"
    | "jobTitle"
    | "lastName"
    | "projectId"
    | "secondsClockedSinceOrgMidnight"
    | "taskId"
  > & {
    currentProject: PickPlus<User["currentProject"], "id" | "name">
    currentTask: PickPlus<User["currentTask"], "id" | "name">
  }
  refetchUser: () => void
}

export const UserQuickActions: FC<Props> = ({ refetchUser, user }) => {
  const { fullDurationInSeconds } = useUpdatingUserClockedInHoursAndMinutes(user)
  const reassignUserTaskFormModalProps = useModalProps("Reassign Users")
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  return (
    <div>
      <div className="flex justify-between items-center min-h-[40px]">
        <H5>Quick Actions</H5>
        {hasPermissionTo("user:update") && (
          <Button variant="text" onClick={() => pushDrawer(<UserEditDrawer userId={user.id} />, "UserEdit")}>
            Edit User
          </Button>
        )}
      </div>
      <hr />
      <QuickActionContainer>
        <QuickActionRow
          icon={<BiStopwatch className={clsx("size-6", user.isClockedIn && "text-blue-600")} />}
          title="Time Status"
          subTitle={
            <>
              <p>Clocked {user.isClockedIn ? "in" : "out"}</p>
              <p>/</p>
              <p>
                <HoursAndMinutes seconds={fullDurationInSeconds} />
              </p>
            </>
          }
          action={
            <RenderIf
              permissionsInclude={["timeEntry:clockInOut"]}
              context={{ projectId: user.currentProjectId, userId: user.id }}
            >
              <ClockInOut user={user} />
            </RenderIf>
          }
        />

        <QuickActionRow
          icon={<BiLayer className="size-5" />}
          title="Current Assignment"
          subTitle={
            <>
              <p>
                <Link href={`/projects/${user?.projectId}`} className="hover:underline">
                  {user.currentProject?.name}
                </Link>
              </p>
              <p>/</p>
              {user.currentTask?.id && (
                <p>
                  <DrawerLink
                    href={`/projects/${user?.projectId}/${user.taskId}`}
                    className="hover:underline"
                    component={<TaskDrawer taskId={user.currentTask.id} />}
                    drawerName="Task"
                  >
                    {user.currentTask?.name}
                  </DrawerLink>
                </p>
              )}
            </>
          }
          action={
            <RenderIf permissionsInclude={["user:assign"]} context={{ userId: user.id }}>
              <ButtonFilled
                color="black"
                onClick={() => {
                  reassignUserTaskFormModalProps.handleOpenModal()
                }}
                type="button"
              >
                Reassign
              </ButtonFilled>
            </RenderIf>
          }
        />

        <QuickActionRow
          icon={<BiWifiOff className="size-5" />}
          title="Offline Events"
          subTitle="See events submitted by this user while offline in the mobile app"
          action={
            <RenderIf permissionsInclude={["user:assign"]}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => pushDrawer(<UserDrawer userId={user.id} tab="offline-events" />, "User")}
                type="button"
              >
                Open Offline Events
              </Button>
            </RenderIf>
          }
        />
      </QuickActionContainer>

      {reassignUserTaskFormModalProps.isOpen && (
        <DeprecatedModal className="overflow-y-visible" {...reassignUserTaskFormModalProps}>
          <ReassignUserTaskForm
            onCancel={() => {
              reassignUserTaskFormModalProps.handleCloseModal()
            }}
            onSuccess={() => {
              successSnack("Selected users have been successfully reassigned.")
              refetchUser()
              reassignUserTaskFormModalProps.handleCloseModal()
            }}
            preselectedUsers={[user]}
          />
        </DeprecatedModal>
      )}
    </div>
  )
}
