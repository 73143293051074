import { LinearProgress, ListItemText, Typography, useMediaQuery, useTheme } from "@mui/material"
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowSelectionModel } from "@mui/x-data-grid-pro"
import { useRouter } from "next/router"
import { parseAsArrayOf, parseAsBoolean, parseAsString, parseAsStringEnum, useQueryState } from "nuqs"
import { CSSProperties, FC, SetStateAction, useContext, useEffect, useState } from "react"
import {
  BiEdit,
  BiExit,
  BiHealth,
  BiLayer,
  BiListUl,
  BiRefresh,
  BiSortAlt2,
  BiStopwatch,
  BiTrash,
  BiUserCircle,
  BiUserPlus,
} from "react-icons/bi"
import { useQuery } from "urql"
import {
  ListVisibilityFilter,
  SortByInput,
  SortDirectionInput,
  TeamListQuery,
  useBulkClockInMutation,
  useBulkClockOutMutation,
  useResendUserInvitesMutation,
  useUserRestoreMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { Project, Task } from "../../../graphql/generated/gql/graphql"
import { parseString, uniqBy } from "../../../helpers/util-functions"
import { useClockedInUsers } from "../../../hooks/useClockedInUsers"
import { useClockedOutUsers } from "../../../hooks/useClockedOutUsers"
import { useHandleError } from "../../../hooks/useHandleError"
import { useListVisibilityFilter } from "../../../hooks/useListVisibilityFilter"
import { OrganizationSettingsContext } from "../../../providers/OrganizationSettingsProvider"
import { PermissionsContext } from "../../../providers/PermissionsProvider/PermissionsProvider"
import { PickPlus } from "../../../types/helpers"
import { ClockInOut } from "../../ClockInOut"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { HeaderActions } from "../../HeaderActions"
import { IconLabel } from "../../IconLabel"
import { ConfirmArchiveUserModal } from "../../Modals/components/ConfirmArchiveUserModal"
import { InjuryReportModal } from "../../Modals/components/InjuryReportModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { QuickMenu } from "../../QuickMenu"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { MenuItem as MenuItemType } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"
import { UserBadge } from "../../UserBadge"
import { UserTimeCounter } from "../../UserTimeCounter"
import { SearchBar } from "../../deprecated/SearchBar"
import DeprecatedModal from "../../deprecated/StandardModal"
import { TaskDrawer } from "../Drawer/components/Task/TaskDrawer"
import { UserCreationDrawer } from "../Drawer/components/User/UserCreationDrawer"
import { UserDrawer } from "../Drawer/components/User/UserDrawer"
import { UserEditDrawer } from "../Drawer/components/User/UserEditDrawer"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { AssignUserForm } from "../Projects/TeamList/AssignUserForm"
import { ReassignUserTaskForm } from "../User/ReassignUserTaskForm/ReassignUserTaskForm"
import { ModernUserWithTimeEntry } from "./BulkTeamActions"
import { BulkClockInModalContents } from "./BulkTeamActions/BulkClockInModalContents"
import { BulkClockOutModal } from "./BulkTeamActions/BulkClockOutModal"
import { handleBulkClockIn, handleBulkClockOut } from "./BulkTeamActions/helpers/legacyBulkClockActions"
import { TeamListBillingClassificationFilter } from "./TeamListFilters/TeamListBillingClassificationFilter"
import { TeamListOtherFilter } from "./TeamListFilters/TeamListOtherFilter"
import { TeamListStatusFilter } from "./TeamListFilters/TeamListStatusFilter"
import { TeamListTimeFilter } from "./TeamListFilters/TeamListTimeFilter"
import { TeamListVisibilityFilter } from "./TeamListFilters/TeamListVisibilityFilter"

export enum TeamListStatus {
  Active = "active",
  Archived = "archived",
}
export type SortDirection = "asc" | "desc"

type ReassignProjectModalType = {
  project?: PickPlus<Project, "id">
}
const emptyModalData = { project: undefined, projectUsers: [] }

const TeamListDocument = graphql(`
  query TeamList(
    $first: Int
    $after: String
    $filter: UserFilter!
    $sort: UserSort!
    $visibilityFilter: ListVisibilityFilter
  ) {
    users(first: $first, after: $after, filter: $filter, sort: $sort, visibilityFilter: $visibilityFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          organizationId
          currentProjectId
          currentTaskId
          projectId
          taskId
          invitationPending
          archived
          assetCount
          firstName
          imageUrl
          jobTitle
          lastName
          isClockedIn
          secondsClockedSinceOrgMidnight
          currentTask {
            id
            name
          }
          currentProject {
            id
            name
          }
        }
      }
    }
  }
`)

type UserNode = NonNullable<TeamListQuery["users"]["edges"][0]>["node"]
type RowFormat = {
  id: UserNode["id"]
  name: UserNode["firstName"] | UserNode["lastName"]
  project: UserNode["currentProject"]["name"]
  task: UserNode["currentTask"]["name"]
  user: UserNode
}

const mapPaginatedUser = (data: TeamListQuery | undefined) => {
  const edges = data?.users.edges || []
  const users: UserNode[] = []

  for (const edge of edges) {
    if (edge) users.push(edge.node)
  }

  return users
}

type Props = {
  project?: PickPlus<Project, "id" | "isArchived">
  task?: PickPlus<Task, "id" | "name" | "isComplete" | "isDefault"> & {
    project: PickPlus<Task["project"], "isArchived">
  }
  style: CSSProperties
}

export const TeamList: FC<Props> = ({ style, project, task }) => {
  // There are three scenarios for the team list:
  // 1. The user is viewing the team list from the main team tab
  // 2. The user is viewing the team list from a project/team tab
  // 3. The user is viewing the team list from a project/task/team tab

  // The first scenario is the default
  // The second scenario is identified by the presence of a project prop and the absence of a task prop
  // The third scenario is identified by the presence of a project and task prop

  const isProjectView = !!project && !task
  const isTaskView = !!project && !!task

  const router = useRouter()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("lg"))
  const { push: pushDrawer } = useContext(DrawerContext)
  const { hasPermissionTo } = useContext(PermissionsContext)

  const [, restoreUserMutation] = useUserRestoreMutation()
  const [, resendUserInvitesMutation] = useResendUserInvitesMutation()

  const [cursor, setCursor] = useState<string | null>(null)
  const [resetList, setResetList] = useState(false)
  const [updateList, setUpdateList] = useState<string[]>([])
  const [nextCursor, setNextCursor] = useState<string | null>(null)
  const [loadedUsers, setLoadedUsers] = useState<UserNode[]>()
  const [selectedUsers, setSelectedUsers] = useState<ModernUserWithTimeEntry[]>([])

  const reassignUserTaskModalProps = useModalProps("Reassign User")
  const archiveUserModalProps = useModalProps("Archive User")
  const assignToProjectFormModalProps = useModalProps("Assign to project")
  const injuryReportModalProps = useModalProps("Injury Report")

  const [currTaskId, setCurrTaskId] = useState<string | undefined>(undefined)
  const [modalData, updateModalData] = useState<ReassignProjectModalType>(emptyModalData)
  const [rowsSelected, setRowsSelected] = useState<GridRowSelectionModel>([])

  const [userToArchive, setUserToArchive] = useState<UserNode | null>(null)
  const [injuryReportUser, setInjuryReportUser] = useState<UserNode | null>(null)
  const [rowCountState, setRowCountState] = useState(0)

  // Begin Query State
  const [visibilityFilter, setVisibilityFilter] = useListVisibilityFilter(
    "user:read",
    parseString<ListVisibilityFilter | undefined>(router.query.visibilityFilter)
  )

  const [sortDirection, setSortDirection] = useQueryState(
    "sortDirection",
    parseAsStringEnum<SortDirectionInput>(Object.values(SortDirectionInput))
      .withDefault(SortDirectionInput.Asc)
      .withOptions({
        clearOnDefault: true,
      })
  )
  const [isClockedIn, setIsClockedIn] = useQueryState("isClockedIn", parseAsBoolean)
  const [invitationPending, setInvitationPending] = useQueryState("invitationPending", parseAsBoolean)
  const [billingClassificationIds, setBillingClassificationIds] = useQueryState(
    "billingClassifications",
    parseAsArrayOf(parseAsString)
  )

  const [searchText, setSearchText] = useQueryState("search", {
    clearOnDefault: true,
  })
  const [status, setStatus] = useQueryState(
    "status",
    parseAsStringEnum<TeamListStatus>(Object.values(TeamListStatus)).withDefault(TeamListStatus.Active).withOptions({
      clearOnDefault: true,
    })
  )
  const isArchived = status === "archived"
  // End query state

  const bulkClockInModalProps = useModalProps("Bulk Clock In")
  const bulkClockOutModalProps = useModalProps("Bulk Clock Out")
  const clockedInUsers = useClockedInUsers(selectedUsers)
  const clockedOutUsers = useClockedOutUsers(selectedUsers)
  const { requireTimeEntryEvidence } = useContext(OrganizationSettingsContext)
  const [, bulkClockInMutation] = useBulkClockInMutation()
  const [, bulkClockOutMutation] = useBulkClockOutMutation()
  const loadingIndicatorModalProps = useModalProps()
  const onSuccess = () => {
    setUpdateList(rowsSelected as string[])
    setRowsSelected([])
  }

  const [{ data, fetching: isLoadingUsers, error: usersError }] = useQuery({
    query: TeamListDocument,
    variables: {
      first: 50,
      after: cursor,
      filter: {
        archived: isArchived,
        billingClassificationIds,
        isClockedIn,
        invitationPending,
        searchText,
        projectId: project?.id,
        taskId: task?.id,
      },
      sort: {
        by: "firstName" as SortByInput,
        direction: sortDirection,
      },
      visibilityFilter,
    },
  })

  const [{ data: updateData, error: updateUserError }] = useQuery({
    query: TeamListDocument,
    pause: updateList.length === 0,
    variables: {
      first: updateList.length,
      filter: { userIds: updateList },
      sort: { by: "firstName" as SortByInput, direction: sortDirection as SortDirectionInput },
      visibilityFilter,
    },
  })

  useHandleError(usersError, "There was an error loading user and task data.")
  useHandleError(updateUserError, "There was an problem refreshing the updated user.")

  useEffect(() => {
    const { endCursor } = data?.users.pageInfo || {}
    const users = mapPaginatedUser(data)
    if (!users.length) {
      if (resetList) setLoadedUsers([])
      return
    }

    // Restart the paginated list
    if (resetList) {
      setLoadedUsers(users)
    } else {
      // Add the next page to the paginated list
      setLoadedUsers((current) =>
        (current ? uniqBy(current.concat(users), (user) => user.id) : users).filter(
          (user) => user.archived === isArchived
        )
      )
    }

    setResetList(false)

    if (endCursor) setNextCursor(endCursor)

    // While we are looping the data, we can also update the row count
    setRowCountState(data?.users.totalCount || 0)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    // Update the existing user list without changing the loaded pagination
    if (!updateList.length || !updateData) return
    const updatedUsers = mapPaginatedUser(updateData)

    const updatedUserList = loadedUsers
      ?.map((loadedUser) => {
        const updatedUser = updatedUsers.find((userSet) => userSet.id === loadedUser.id)
        return updatedUser || loadedUser
      })
      .filter((user) => user.archived === isArchived)

    setLoadedUsers(updatedUserList)

    setUpdateList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData])

  useEffect(() => {
    // This logic allows us to select many users between backend search results
    // Keep existing user data, but filter out any deselection
    const users = selectedUsers.filter((user) => rowsSelected.includes(user.id))

    for (const userId of rowsSelected) {
      // If we already have user data for this selection, move on.
      if (users.some((user) => userId === user.id)) continue
      // Find user data for this selection
      const found = loadedUsers?.find((user) => user.id === userId)
      if (found) users.push(found)
    }

    setSelectedUsers(users)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected])

  const handleReassignTask = (user: ModernUserWithTimeEntry) => {
    if (!selectedUsers.find((u) => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user])
    }

    updateModalData({
      project: { id: user.currentProjectId || user.projectId! },
    })

    setCurrTaskId(user?.currentTaskId ?? user?.taskId ?? undefined)
    reassignUserTaskModalProps.handleOpenModal()
  }

  const projectMenuItems = (user: UserNode): MenuItemType[][] => {
    const projectId = user.projectId
    const menuItems: MenuItemType[][] = []

    menuItems.push([
      {
        value: "View project",
        Icon: BiLayer,
        onClick: () => {
          router.push(`/projects/${user.projectId}`)
        },
        isDisabled: !hasPermissionTo("project:read", { projectId }),
        iconStyles: "w-5 h-5",
      },
    ])

    menuItems.push([
      {
        value: "Reassign",
        onClick: () => handleReassignTask(user),
        isDisabled: !hasPermissionTo("user:assign", { projectId }),
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
      },
    ])

    return menuItems
  }

  const taskMenuItems = (user: UserNode): MenuItemType[][] => {
    const projectId = user.projectId
    const menuItems: MenuItemType[][] = []
    menuItems.push([
      {
        value: "View task",
        Icon: BiListUl,
        isDisabled: !hasPermissionTo("task:read", { projectId, taskId: user.taskId }),
        onClick: () => {
          if (user.taskId) pushDrawer(<TaskDrawer taskId={user.taskId} />, "Task")
        },
        iconStyles: "w-5 h-5",
      },
    ])

    menuItems.push([
      {
        value: "Reassign",
        onClick: () => handleReassignTask(user),
        isDisabled: !hasPermissionTo("user:assign", { projectId }),
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 w-5 h-5",
      },
    ])

    return menuItems
  }

  const headerActions: MenuItemType[][] = []

  if (isProjectView || isTaskView) {
    const headerActionSection: MenuItemType[] = []

    if (clockedOutUsers.length > 0) {
      headerActionSection.push({
        // Clock in
        value: <Typography>Clock in</Typography>,
        Icon: BiStopwatch,
        iconStyles: "w-5 h-5",
        requiredPermission: "timeEntry:create",
        onClick: () => {
          if (requireTimeEntryEvidence) {
            bulkClockInModalProps.handleOpenModal()
          } else {
            handleBulkClockIn(clockedOutUsers, bulkClockInMutation, loadingIndicatorModalProps, onSuccess)
          }
        },
      })
    }

    if (clockedInUsers.length > 0) {
      headerActionSection.push({
        // Clock out
        value: <Typography>Clock out</Typography>,
        Icon: BiExit,
        iconStyles: "w-5 h-5",
        requiredPermission: "timeEntry:create",
        onClick: () => {
          if (requireTimeEntryEvidence) {
            bulkClockOutModalProps.handleOpenModal()
          } else {
            handleBulkClockOut(clockedInUsers, bulkClockOutMutation, loadingIndicatorModalProps, onSuccess)
          }
        },
      })
    }

    headerActionSection.push({
      value:
        rowsSelected.length >= 1 ? (
          <Typography>Reassign</Typography>
        ) : (
          <Typography>Assign to {isTaskView ? "task" : "project"}</Typography>
        ),
      Icon: BiSortAlt2,
      iconStyles: "rotate-90 size-5",
      requiredPermission: "user:assign",
      onClick: () => {
        reassignUserTaskModalProps.handleOpenModal()
      },
    })

    headerActions.push(headerActionSection)
  } else {
    const headerActionSection: MenuItemType[] = [
      {
        // Reassign
        value: <Typography>Reassign</Typography>,
        Icon: BiSortAlt2,
        iconStyles: "rotate-90 size-5",
        requiredPermission: "user:assign",
        onClick: () => {
          reassignUserTaskModalProps.handleOpenModal()
        },
      },
      {
        // Add user
        value: <Typography>Add User</Typography>,
        Icon: BiUserPlus,
        onClick: () => pushDrawer(<UserCreationDrawer />, "UserCreate"),
        requiredPermission: "user:create",
      },
    ]

    if (clockedInUsers.length > 0) {
      headerActionSection.unshift({
        // Clock out
        value: <Typography>Clock out</Typography>,
        Icon: BiExit,
        iconStyles: "w-5 h-5",
        requiredPermission: "timeEntry:create",
        onClick: () => {
          if (requireTimeEntryEvidence) {
            bulkClockOutModalProps.handleOpenModal()
          } else {
            handleBulkClockOut(clockedInUsers, bulkClockOutMutation, loadingIndicatorModalProps, onSuccess)
          }
        },
      })
    }

    if (clockedOutUsers.length > 0) {
      headerActionSection.unshift({
        // Clock in
        value: <Typography>Clock in</Typography>,
        Icon: BiStopwatch,
        iconStyles: "w-5 h-5",
        requiredPermission: "timeEntry:create",
        onClick: () => {
          if (requireTimeEntryEvidence) {
            bulkClockInModalProps.handleOpenModal()
          } else {
            handleBulkClockIn(clockedOutUsers, bulkClockInMutation, loadingIndicatorModalProps, onSuccess)
          }
        },
      })
    }

    headerActions.push(headerActionSection)
  }

  const replaceList = () => {
    setCursor(null)
    setNextCursor(null)
    setResetList(true)
  }

  const setSearchValue = (value: string | null) => {
    replaceList()
    setSearchText(value)
  }

  const setStatusFilter = (value: TeamListStatus) => {
    replaceList()
    setStatus(value)
  }

  const setSortOrder = (value?: SortDirectionInput | null) => {
    replaceList()
    setSortDirection(value || SortDirectionInput.Asc)
  }

  const setVisibilityFilterValue = (value: SetStateAction<ListVisibilityFilter>) => {
    replaceList()
    setVisibilityFilter(value)
  }

  const handleUnarchiveUser = async (user: UserNode) => {
    const { error } = await restoreUserMutation({ id: user.id })
    if (error) return errorSnack("Error unarchiving user")

    setUpdateList([user.id])
    successSnack("User unarchived successfully")
  }

  const handleResendUserInvite = async (userId: string) => {
    const { error } = await resendUserInvitesMutation({ userIds: [userId] })
    if (error) return errorSnack("Error unarchiving user")

    setUpdateList([userId])
    successSnack("User invite sent")
  }

  const handleArchiveUser = async (userId: string) => {
    setLoadedUsers((users) => users?.filter((user) => user.id !== userId))
    archiveUserModalProps.handleCloseModal()
  }

  const columnProps = {
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 150,
      ...columnProps,
      sortable: true,
      renderCell: ({ row }: GridRenderCellParams<RowFormat>) => (
        <UserBadge user={row.user} invitationPending={row.user.invitationPending} />
      ),
    },
    {
      field: "project",
      headerName: "Project",
      flex: 1,
      minWidth: 90,
      maxWidth: 200,
      ...columnProps,
      renderCell: ({ row }: GridRenderCellParams<RowFormat>) => (
        <QuickMenu
          items={projectMenuItems(row.user)}
          className="w-full"
          menuButtonClassName="w-full text-left p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all"
          disabled={
            row.user?.archived ||
            rowsSelected.length > 0 ||
            !(
              hasPermissionTo("project:read", { projectId: row.user.projectId }) ||
              hasPermissionTo("user:assign", { userId: row.user.id })
            )
          }
        >
          <IconLabel icon={BiLayer} label={row.project} />
        </QuickMenu>
      ),
    },
    {
      field: "task",
      headerName: "Task",
      flex: 1,
      minWidth: 90,
      maxWidth: 200,
      ...columnProps,
      renderCell: ({ row }: GridRenderCellParams<RowFormat>) => (
        <QuickMenu
          items={taskMenuItems(row.user)}
          className="w-full"
          menuButtonClassName="w-full text-left p-2.5 bg-gray-100 hover:bg-gray-200 hover:rounded transition-all md:col-span-2"
          disabled={
            row.user?.archived ||
            rowsSelected.length > 0 ||
            !(
              hasPermissionTo("task:read", { projectId: row.user.projectId, taskId: row.user.taskId }) ||
              hasPermissionTo("user:assign", { userId: row.user.id })
            )
          }
        >
          <IconLabel icon={BiListUl} label={row.task} />
        </QuickMenu>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      maxWidth: 150,
      ...columnProps,
      renderCell: ({ row }: GridRenderCellParams<RowFormat>) => (
        <div className="w-full">
          <ClockInOut
            disabled={
              rowsSelected.length > 0 ||
              !hasPermissionTo("timeEntry:clockInOut", { userId: row.id, projectId: row.user.projectId })
            }
            user={row.user}
            onSuccess={() => setUpdateList([row.id])}
          />
        </div>
      ),
    },
    {
      field: "time",
      headerName: "Today's Time",
      flex: 1,
      minWidth: 90,
      maxWidth: 150,
      ...columnProps,
      renderCell: ({ row }: GridRenderCellParams<RowFormat>) => (
        <RenderIf
          permissionsInclude={["timeEntry:read"]}
          fallbackComponent={<div>—</div>}
          context={{ projectId: row.user.projectId, userId: row.user.id }}
        >
          <UserTimeCounter user={row.user} />
        </RenderIf>
      ),
    },
    {
      field: "menu",
      headerName: "",
      flex: 0.5,
      editable: false,
      resizable: false,
      hideable: false,
      ...columnProps,
      align: "right",
      renderCell: ({ row }: GridRenderCellParams<RowFormat>) => {
        const { id: userId, taskId, projectId, archived } = row.user

        const navMenuSection: MenuItemType[] = [
          {
            value: "View profile",
            Icon: BiUserCircle,
            isDisabled: !hasPermissionTo("user:read", { userId }),
            onClick: () => pushDrawer(<UserDrawer userId={userId} />, "User"),
            iconStyles: "w-5 h-5",
          },
        ]
        const clockMenuSection: MenuItemType[] = []
        const archiveMenuSection: MenuItemType[] = []
        const injuryReportMenuSection: MenuItemType[] = []

        mobile &&
          hasPermissionTo("project:read", { projectId }) &&
          navMenuSection.push({
            value: (
              <>
                <ListItemText>View project</ListItemText>
                <Typography className="ml-10 text-gray-400 truncate">{row.project}</Typography>
              </>
            ),
            Icon: BiLayer,
            isDisabled: !hasPermissionTo("project:read", { projectId }),
            onClick: () => router.push(`/projects/${projectId}/${taskId}`),
            iconStyles: "w-5 h-5",
          })
        mobile &&
          hasPermissionTo("task:read", { projectId }) &&
          navMenuSection.push({
            value: (
              <>
                <ListItemText>View task</ListItemText>
                <Typography className="ml-10 text-gray-400 truncate">{row.task}</Typography>
              </>
            ),
            Icon: BiListUl,
            isDisabled: !hasPermissionTo("task:read", { projectId }),
            onClick: () => router.push(`/projects/${projectId}/${taskId}`),
            iconStyles: "w-5 h-5",
          })

        hasPermissionTo("user:assign", { userId }) &&
          !archived &&
          navMenuSection.push({
            value: "Reassign",
            Icon: BiSortAlt2,
            iconStyles: "rotate-90 w-5 h-5",
            isDisabled: !hasPermissionTo("user:assign", { userId }) || archived,
            onClick: () => handleReassignTask(row.user),
          })

        hasPermissionTo("user:update") &&
          navMenuSection.push({
            value: "Edit",
            onClick: () => pushDrawer(<UserEditDrawer userId={userId} />, "UserEdit"),
            Icon: BiEdit,
          })

        hasPermissionTo("user:create") &&
          row.user.invitationPending &&
          navMenuSection.push({
            value: <Typography>Resend app invite</Typography>,
            Icon: BiRefresh,
            onClick: () => handleResendUserInvite(row.user.id),
          })

        {
          mobile &&
            clockMenuSection.push({
              value: (
                <RenderIf
                  permissionsInclude={["timeEntry:clockInOut"]}
                  context={{ projectId: row.user.projectId, userId: row.user.id }}
                >
                  <div className="flex justify-between w-full pt-1.5">
                    <ClockInOut
                      disabled={rowsSelected.length > 0}
                      user={row.user}
                      onSuccess={() => setUpdateList([row.user.id])}
                    />
                    <div className="justify-self-end self-center">
                      <UserTimeCounter user={row.user} />
                    </div>
                  </div>
                </RenderIf>
              ),
            })
        }

        if (hasPermissionTo("user:update")) {
          injuryReportMenuSection.push({
            value: "Report Injury",
            onClick: () => {
              setInjuryReportUser(row.user)
              injuryReportModalProps.handleOpenModal()
            },
            Icon: BiHealth,
            color: "orange",
          })
        }

        hasPermissionTo("user:assign", { userId }) &&
          !archived &&
          archiveMenuSection.push({
            value: "Archive user",
            Icon: BiTrash,
            isDisabled: !hasPermissionTo("user:archive", { userId }) || archived,
            onClick: () => {
              setUserToArchive(row.user)
              archiveUserModalProps.handleOpenModal()
            },
            color: "red",
          })

        hasPermissionTo("user:create", { userId }) &&
          archived &&
          archiveMenuSection.push({
            value: <Typography color="error">Unarchive user</Typography>,
            Icon: BiTrash,
            isDisabled: !hasPermissionTo("user:create", { userId }) || !archived,
            onClick: () => handleUnarchiveUser(row.user),
          })

        const menuItems: MenuItemType[][] = [
          navMenuSection,
          clockMenuSection,
          injuryReportMenuSection,
          archiveMenuSection,
        ]

        return (
          <QuickMenu items={menuItems} buttonShape="round">
            <QuickMenuDotsHorizontal />
          </QuickMenu>
        )
      },
    },
  ]

  return (
    <div className="flex flex-col w-full" style={style}>
      {/* Header */}
      <div className="grid grid-cols-12 gap-x-2 gap-y-4 md:gap-x-3 md:gap-y-5">
        <div className="col-span-7 md:col-span-5">
          <SearchBar fullWidth onChange={setSearchValue} onCancel={setSearchValue} />
        </div>

        <div className="col-span-5 md:col-span-7">
          <HeaderActions items={headerActions} />
        </div>

        <div className="col-span-12 flex gap-2">
          <TeamListStatusFilter
            selectedStatus={status}
            setSelectedStatus={setStatusFilter}
            resetOtherFilters={() => {
              setIsClockedIn(null)
              setVisibilityFilterValue(ListVisibilityFilter.All)
              setInvitationPending(null)
            }}
          />
          {status === "active" && (
            <>
              <TeamListVisibilityFilter
                visibilityFilter={visibilityFilter}
                setVisibilityFilter={setVisibilityFilterValue}
              />
              <TeamListTimeFilter
                isClockedIn={isClockedIn}
                setIsClockedIn={(value: boolean | null) => {
                  replaceList()
                  setIsClockedIn(value)
                }}
              />
              <DevelopmentFeatureFlag name="Billing Classifications">
                <TeamListBillingClassificationFilter
                  billingClassificationIds={billingClassificationIds}
                  setBillingClassificationIds={(value: SetStateAction<string[] | null>) => {
                    replaceList()
                    setBillingClassificationIds(value)
                  }}
                />
              </DevelopmentFeatureFlag>
              <TeamListOtherFilter
                invitationPending={invitationPending}
                setInvitationPending={(value: boolean | null) => {
                  replaceList()
                  setInvitationPending(value)
                }}
              />
            </>
          )}
        </div>
      </div>

      {/* Body */}

      <div className="overflow-y-hidden">
        <DataGridPro
          columns={columns}
          rows={(loadedUsers || []).map((user) => ({
            id: user.id,
            name: user.firstName + " " + user.lastName,
            project: user.currentProject.name,
            task: user.currentTask.name,
            user: user,
          }))}
          columnVisibilityModel={{
            name: true,
            project: !mobile && !isProjectView && !isTaskView,
            task: !mobile,
            status: !mobile,
            time: !mobile,
          }}
          checkboxSelection={hasPermissionTo(["timeEntry:create", "user:assign"])}
          density="comfortable"
          disableRowSelectionOnClick
          filterMode="server"
          keepNonExistentRowsSelected
          loading={isLoadingUsers}
          onRowSelectionModelChange={setRowsSelected}
          onRowsScrollEnd={() => setCursor(nextCursor)}
          onSortModelChange={([model]) => setSortOrder(model?.sort as SortDirectionInput)}
          paginationMode="server"
          rowCount={rowCountState}
          rowSelectionModel={rowsSelected}
          scrollEndThreshold={200}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => <EmptyStateBlock label="No team members" />,
          }}
          sortingMode="server"
        />
      </div>

      {reassignUserTaskModalProps.isOpen && (
        <DeprecatedModal {...reassignUserTaskModalProps}>
          <ReassignUserTaskForm
            previousTaskId={currTaskId}
            newProjectId={project?.id || modalData.project?.id}
            newTaskId={task?.id}
            onCancel={async () => {
              updateModalData(emptyModalData)
              setSelectedUsers([])
              setRowsSelected([])
              reassignUserTaskModalProps.handleCloseModal()
            }}
            onSuccess={async (updated) => {
              setUpdateList(updated.map(({ userId }) => userId))
              updateModalData(emptyModalData)
              setSelectedUsers([])
              setRowsSelected([])
              reassignUserTaskModalProps.handleCloseModal()
            }}
            preselectedUsers={selectedUsers}
          />
        </DeprecatedModal>
      )}
      {userToArchive && (
        <ConfirmArchiveUserModal
          modalProps={archiveUserModalProps}
          user={userToArchive}
          onSuccess={handleArchiveUser}
        />
      )}
      {injuryReportUser && <InjuryReportModal modalProps={injuryReportModalProps} user={injuryReportUser} />}

      <DeprecatedModal {...bulkClockInModalProps}>
        <BulkClockInModalContents
          onCancel={bulkClockInModalProps.handleCloseModal}
          onSuccess={() => {
            bulkClockInModalProps.handleCloseModal()
            onSuccess?.()
          }}
          userIds={clockedOutUsers.map(({ id }) => id)}
        />
      </DeprecatedModal>

      <BulkClockOutModal {...bulkClockOutModalProps} userIds={clockedInUsers.map(({ id }) => id)} />

      {project && (
        <DeprecatedModal className="overflow-y-visible" {...assignToProjectFormModalProps}>
          <AssignUserForm
            projectId={project.id}
            task={task}
            onSuccess={async () => {
              assignToProjectFormModalProps.handleCloseModal()
            }}
            onCancel={assignToProjectFormModalProps.handleCloseModal}
          />
        </DeprecatedModal>
      )}
    </div>
  )
}
