import format from "date-fns/format"
import isSameDay from "date-fns/isSameDay"
import { Form, Formik, FormikHelpers } from "formik"
import { FC, useEffect, useMemo, useRef } from "react"
import { Maybe, TimeEntry, User, useTimeEntryEditMutation } from "../../../../graphql/generated/client-types-and-hooks"
import { getTimeEntryArrayTotalSeconds } from "../../../../helpers/timeEntries/getTimeEntryArrayTotalSeconds"
import { secondsToFormattedString } from "../../../../helpers/dateAndTime/time-utility"
import { validateTimeEntries } from "../../../../helpers/timeEntries/validateTimeEntry"
import { PickPlus } from "../../../../types/helpers"
import { ButtonFilled, ButtonHollow } from "../../../deprecated"
import { LoadingIndicator } from "../../../Loading/LoadingIndicator"
import { ModalBody } from "../../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../../Modals/components/Elements/ModalFooter"
import { errorSnack } from "../../../Notistack/ThemedSnackbars"
import { EditableTimeEntry } from "./EditableTimeEntry"

type Values = {
  [key: string]: Props["timeEntries"][0] & {
    selectedProjectId: [string]
    selectedTaskId: string
  }
}

export type TimeEntryWithProjectAndTaskName = PickPlus<
  TimeEntry,
  | "id"
  | "projectId"
  | "taskId"
  | "endAt"
  | "startAt"
  | "durationInSeconds"
  | "isBreak"
  | "isUnpaid"
  | "signInPhotoUrl"
  | "signOutPhotoUrl"
> & { project: PickPlus<TimeEntry["project"], "name">; task: PickPlus<TimeEntry["task"], "name"> }

export type TimeEntrySplit = {
  splitByStartAt: boolean
  splitByEndAt: boolean
  preSplitStart: Date
  preSplitEnd?: Maybe<Date>
}

type Props = {
  onCancel: () => void
  onSuccess: () => void
  timeEntries: TimeEntryWithProjectAndTaskName[]
  timeEntrySplit?: TimeEntrySplit
  user: PickPlus<User, "id" | "taskId" | "currentTaskId">
  selectedTimeEntryId?: string
}

const generateInitialValuesFromProps = (timeEntries: Props["timeEntries"]): Values => {
  return Object.fromEntries(
    timeEntries.map((timeEntry) => {
      return [
        timeEntry.id,
        {
          ...timeEntry,
          selectedProjectId: [timeEntry.projectId],
          selectedTaskId: timeEntry.taskId,
        },
      ]
    })
  )
}

export const EditDayActivityForm: FC<Props> = ({
  onCancel,
  onSuccess,
  timeEntries,
  timeEntrySplit,
  user,
  selectedTimeEntryId,
}) => {
  const initialValues = useMemo(() => {
    return generateInitialValuesFromProps(timeEntries)
  }, [timeEntries])
  const [{ fetching: mutationIsFetching }, editTimeEntryMutation] = useTimeEntryEditMutation()
  const now = new Date()

  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({})

  useEffect(() => {
    if (selectedTimeEntryId && itemRefs.current[selectedTimeEntryId]) {
      const element = itemRefs.current[selectedTimeEntryId]
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }
    }
  }, [selectedTimeEntryId])

  const handleSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    const firstTimeEntryId = timeEntries[0].id
    const lastTimeEntryId = timeEntries[timeEntries.length - 1].id
    for (const [id, { startAt, endAt, selectedTaskId }] of Object.entries(values)) {
      const initialValue = initialValues[id]

      const isFirstTimeEntry = id === firstTimeEntryId
      const isSplitByStartAt = isFirstTimeEntry && !!timeEntrySplit?.splitByStartAt
      const hasChangedStartAt = isSplitByStartAt ?? initialValue.startAt === startAt

      const isLastTimeEntry = id === lastTimeEntryId
      const isSplitByEndAt = isLastTimeEntry && !!timeEntrySplit?.splitByEndAt
      const hasChangedEndAt = isSplitByEndAt ?? initialValue.endAt === endAt

      if (hasChangedStartAt && hasChangedEndAt && initialValue.selectedTaskId === selectedTaskId) {
        continue
      }
      // TODO: Capture order of operations in how we apply the updates: criteria...
      // 1) Is shrinking (should be safe to do in forward order),
      // 2) is growing? Should be safe to do in a second pass.
      //
      // Currently, the only optimization is to remove anything that doesn't have updates from the initial
      // state... we don't want to touch it! :D
      if (!selectedTaskId) throw new Error("Invalid data")

      const newStartAt = isSplitByStartAt ? timeEntrySplit?.preSplitStart : startAt
      const newEndAt = isSplitByEndAt ? timeEntrySplit?.preSplitEnd : endAt

      if (newEndAt && newStartAt.getTime() > newEndAt.getTime()) {
        errorSnack(`Start time must be before the end time.`)
        throw new Error("Invalid data")
        continue
      }

      await handleClockEvent(id, selectedTaskId, newStartAt, newEndAt)
    }
    setSubmitting(false)
  }

  const handleClockEvent = (timeEntryId: string, taskId: string, startAt: Date, endAt?: Date | null) => {
    editTimeEntryMutation({ id: timeEntryId, startAt, endAt: endAt || null, taskId }).then((result) => {
      if (result.error) {
        errorSnack("Something went wrong.  Please refresh and try again.")
        console.error(result.error)
      } else {
        onSuccess()
      }
    })
  }

  const totalSeconds = useMemo(() => getTimeEntryArrayTotalSeconds(timeEntries), [timeEntries])

  if (timeEntries.length < 1)
    return (
      <div className="h-48">
        <LoadingIndicator />
      </div>
    )

  const firstTimeEntry = timeEntries[0]

  const allAreSameDay = timeEntries.every((timeEntry) => {
    const start = isSameDay(timeEntry.startAt, firstTimeEntry.startAt)
    const end = timeEntry.endAt ? isSameDay(timeEntry.endAt, firstTimeEntry.startAt) : isSameDay(timeEntry.startAt, now)
    return start && end
  })

  const reversedTimeEntries = timeEntries.sort((a, b) => (a.startAt < b.startAt ? -1 : 1))

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      validateOnBlur={false}
      validate={(values) => validateTimeEntries(values, timeEntries)}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form className="h-full flex flex-col">
          <ModalBody>
            <div>
              <div className="flex flex-col md:flex-row md:justify-between items-baseline mb-4">
                <div className="text-lg font-bold">
                  {format(reversedTimeEntries?.[reversedTimeEntries.length - 1].startAt, "EEEE, MMMM do")}
                </div>
                <div className="text-sm md:text-base text-gray-400">
                  Total: {secondsToFormattedString(totalSeconds)}
                </div>
              </div>

              <div className="transition-all flex flex-col gap-12">
                {timeEntries.map((timeEntry, index) => {
                  const highlight = Boolean(selectedTimeEntryId && selectedTimeEntryId == timeEntry.id)

                  return (
                    <div
                      key={timeEntry.id}
                      ref={(el: HTMLDivElement | null) => {
                        itemRefs.current[timeEntry.id] = el
                      }}
                    >
                      <EditableTimeEntry
                        highlight={highlight}
                        sameDay={allAreSameDay}
                        timeEntries={timeEntries}
                        timeEntry={timeEntry}
                        splitByStartAt={index === 0 ? timeEntrySplit?.splitByStartAt : false}
                        splitByEndAt={index === timeEntries.length - 1 ? timeEntrySplit?.splitByEndAt : false}
                        user={user}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonHollow type="button" onClick={onCancel} disabled={mutationIsFetching || props.isSubmitting}>
              Cancel
            </ButtonHollow>
            <ButtonFilled type="submit" disabled={mutationIsFetching}>
              Save
            </ButtonFilled>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  )
}
