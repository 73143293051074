import { clsx } from "clsx"
import { FC } from "react"
import { BiStopwatch } from "react-icons/bi"
import { User } from "../graphql/generated/client-types-and-hooks"
import { useUpdatingUserClockedInHoursAndMinutes } from "../hooks/useUpdatingUserClockedInHoursAndMinutes"
import { PickPlus } from "../types/helpers"
import { HoursAndMinutes } from "./Time/HoursAndMinutes"

type Props = {
  user: PickPlus<User, "isClockedIn" | "secondsClockedSinceOrgMidnight">
}

export const UserTimeCounter: FC<Props> = ({ user }) => {
  const { fullDurationInSeconds } = useUpdatingUserClockedInHoursAndMinutes(user)

  return (
    <div
      className={clsx(
        "flex items-center gap-x-1 w-full justify-center text-base",
        "md:justify-start",
        user.isClockedIn && `text-blue-600`,
        !user.isClockedIn && `text-gray-400`
      )}
    >
      <BiStopwatch className="size-6" />
      <span>
        <HoursAndMinutes seconds={fullDurationInSeconds} />
      </span>
    </div>
  )
}
