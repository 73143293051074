import { Alert, Box } from "@mui/material"
import { isWithinInterval } from "date-fns"
import { Form, Formik } from "formik"
import { FC, useState } from "react"
import { TimeEntry, User, useTimeEntryCreateMutation } from "../../../graphql/generated/client-types-and-hooks"
import { validateTimeEntry } from "../../../helpers/timeEntries/validateTimeEntry"
import { PickPlus } from "../../../types/helpers"
import { DateTimeOrMaybeJustTimePicker } from "../../Formik/DateTimeOrMaybeJustTimePicker"
import { ProjectAndTasksMultiSelects } from "../../Formik/MultiSelect/implementations/ProjectAndTaskMultiSelects"
import { ModalBody } from "../../Modals/components/Elements/ModalBody"
import { ModalFooter } from "../../Modals/components/Elements/ModalFooter"
import { errorSnack } from "../../Notistack/ThemedSnackbars"
import { ButtonFilled, ButtonHollow } from "../../deprecated"

type Props = {
  onCancel: () => void
  onSuccess: () => void
  user: PickPlus<User, "id" | "currentProjectId" | "currentTaskId"> & {
    project: PickPlus<User["project"], "name">
    task: PickPlus<User["task"], "name">
  }
  timeEntries: Pick<TimeEntry, "id" | "startAt" | "endAt">[]
}

type Values = {
  startAt: Date
  endAt: Date
  selector: {
    selectedProjectId: string[]
    selectedTaskId: string
  }
}

export const CreateTimeEntryForm: FC<Props> = ({ onCancel, user, timeEntries }) => {
  const [{ fetching }, createTimeEntryMutation] = useTimeEntryCreateMutation()
  const [isUnpaid, setIsUnpaid] = useState(false)

  const onSubmit = ({ startAt, endAt, selector: { selectedTaskId } }: Values) => {
    createTimeEntryMutation({ endAt: endAt, startAt: startAt, taskId: selectedTaskId, userId: user.id }).then(
      (result) => {
        if (result.error) {
          errorSnack(result.error.message)
          console.error(result.error)
        } else {
          onCancel()
        }
      }
    )
  }

  return (
    <Formik<Values>
      enableReinitialize={false}
      initialValues={{
        startAt: new Date(),
        endAt: new Date(),
        selector: {
          selectedProjectId: [user.currentProjectId],
          selectedTaskId: user.currentTaskId,
        },
      }}
      validateOnBlur={false}
      validate={(values) => {
        return validateTimeEntry(
          {
            ...values,
            endAt: values.endAt!,
            startAt: values.startAt!,
            timeEntries,
          },
          { requireEndAt: true, requireStartAt: true }
        )
      }}
      onSubmit={onSubmit}
    >
      {({ errors, isValid, values }) => {
        return (
          <Form className="h-full flex flex-col">
            <ModalBody>
              <div className="py-2 flex gap-6 md:gap-4 flex-col">
                <DateTimeOrMaybeJustTimePicker
                  required
                  label="from"
                  name="startAt"
                  disableFuture
                  shouldDisableTime={(date) =>
                    timeEntries.some((timeEntry) =>
                      !!timeEntry.endAt
                        ? isWithinInterval(date, { start: timeEntry.startAt, end: timeEntry.endAt })
                        : false
                    )
                  }
                />
                <DateTimeOrMaybeJustTimePicker
                  required
                  label="to"
                  name="endAt"
                  disableFuture
                  shouldDisableTime={(date) =>
                    date < values.startAt ||
                    timeEntries.some((timeEntry) =>
                      !!timeEntry.endAt
                        ? isWithinInterval(date, { start: timeEntry.startAt, end: timeEntry.endAt })
                        : false
                    )
                  }
                />
              </div>
              <Box className="pt-2">
                <ProjectAndTasksMultiSelects
                  users={[user]}
                  formGroupId="selector"
                  includeCurrentAssignment
                  includeBreakTasks
                  setIsUnpaid={setIsUnpaid}
                />
              </Box>

              <div className="pl-2 mt-3 mb-0">
                {isUnpaid && (
                  <Alert severity="info" className="mt-2">
                    Unpaid time will not be included in the payroll calculation.
                  </Alert>
                )}
              </div>

              <Box className="text-red-600 min-h-[12px]">
                {errors.startAt && <div>{errors.startAt as string}</div>}
                {errors.endAt && <div>{errors.endAt as string}</div>}
              </Box>
            </ModalBody>
            <ModalFooter>
              <ButtonHollow type="button" onClick={onCancel} disabled={fetching}>
                Cancel
              </ButtonHollow>
              <ButtonFilled type="submit" disabled={fetching || !isValid}>
                Add time
              </ButtonFilled>
            </ModalFooter>
          </Form>
        )
      }}
    </Formik>
  )
}