import { WidgetConfigGroup } from "../components/Widgets/availableWidgets"
import { GrantedPermission } from "./Permission"

export type Role = {
  id?: string
  name: string
  permissions: GrantedPermission[]
  order: number
  description?: string
  widgets: WidgetConfigGroup[]
}

export const AdminRoleName = "Admin"
export const TeamMemberRoleName = "Team Member"
