import { Box, Typography } from "@mui/material"
import { TaskDependencyRelationalType } from "@prisma/client"
import { Form, Formik } from "formik"
import { useMemo } from "react"
import { BiGitCompare } from "react-icons/bi"
import { useQuery } from "urql"
import * as Yup from "yup"
import { DropDownMUI, DropDownMUIItem } from "../../../components/DropDownMUI"
import { MuiGroupedSingleSelect } from "../../../components/Formik/MultiSelect/MuiGroupedSingleSelect"
import { createTaskOptionGroups } from "../../../components/Formik/MultiSelect/helpers"
import { NumberInputWithAdornment } from "../../../components/Formik/NumberInputWithAdornment"
import { SliderWithControls } from "../../../components/Formik/SliderWithControls"
import { MuiModal } from "../../../components/Modals/components/Elements/MuiModal"
import { ModalProps } from "../../../components/Modals/hooks/useModalProps"
import { errorSnack } from "../../../components/Notistack/ThemedSnackbars"
import { TaskDependency, TaskDependencyType } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { titleCase } from "../../../helpers/strings/titleCase"

const validationSchema = Yup.object().shape({
  predecessorTaskId: Yup.string().required("Required"),
  dependentTaskId: Yup.string()
    .required("Required")
    .test("not-equal-predecessor-task", "A Task cannot be dependent on itself", function (value) {
      return value !== this.parent.predecessorTaskId
    }),
  type: Yup.string().required("Required"),
  projectId: Yup.string().required("Required"),
  metadata: Yup.object().shape({
    offsetDays: Yup.number().nullable(),
  }),
})

const TaskListQuery = graphql(`
  query ProjectTasksListForDependencies($projectId: String!) {
    project(id: $projectId) {
      id
      unassignedTaskId
      name
      taskDependencies {
        id
        predecessorTaskId
        dependentTaskId
        type
        metadata {
          offsetDays
        }
      }
    }
    taskGroups(projectId: $projectId) {
      id
      name
      sortOrder
    }
    tasks(projectId: $projectId) {
      id
      name
      groupId
      isComplete
      archived
      sortOrder
    }
  }
`)

export const TaskDependencyModal: React.FC<{
  modalProps: ModalProps
  projectId: string
  taskId?: string // If this was opened from a task drawer, we want to use this ID to make sure that the stays as either the predecessor or successor task
  dependency?: Partial<TaskDependency>
  onSave: (values: TaskDependency) => void
}> = ({ modalProps, projectId, taskId, dependency, onSave }) => {
  const [{ data, error, fetching }] = useQuery({
    query: TaskListQuery,
    variables: {
      projectId: projectId,
      archived: false,
    },
    pause: !projectId,
  })

  if (error) {
    errorSnack(`An error occurred while fetching tasks: ${error.message}`)
  }

  const groupedTaskOptions = useMemo(() => {
    const options = { excludedTaskIds: [data?.project?.unassignedTaskId || ""], filterCompletedTasks: false }
    return createTaskOptionGroups(data?.tasks || [], data?.taskGroups || [], options)
  }, [data?.tasks, data?.taskGroups, data?.project?.unassignedTaskId])

  const taskMenuItems: DropDownMUIItem[] = useMemo(() => {
    return data
      ? data.tasks
          .filter((task) => data.project?.unassignedTaskId !== task.id)
          .map((task) => ({ value: (task.id || task.groupId)!, label: task.name }))
      : [{ value: "", label: "" }]
  }, [data])

  if (!taskMenuItems) return

  const handleClose = async (resetForm: () => void) => {
    try {
      modalProps.handleCloseModal()
      resetForm()
    } catch {
      errorSnack("An error occurred, please try again")
    }
  }

  return (
    <Formik<TaskDependency>
      enableReinitialize
      initialValues={
        {
          id: dependency?.id || "",
          predecessorTaskId: dependency?.predecessorTaskId || "",
          dependentTaskId: dependency?.dependentTaskId || "",
          projectId: projectId,
          type: dependency?.type || TaskDependencyType.FinishToStart,
          metadata: dependency?.metadata || { offsetDays: 0 },
        } as TaskDependency
      }
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        try {
          onSave(values)
          handleClose(resetForm)
        } catch {
          errorSnack("An error occurred, please try again")
        }
      }}
    >
      {({ values, handleSubmit, isSubmitting, resetForm }) => {
        return (
          <MuiModal
            contentLabel="Add Dependency"
            submitButtonText="Save"
            variant="small"
            isOpen={modalProps.isOpen}
            disabled={isSubmitting}
            handleCloseModal={() => {
              handleClose(resetForm)
            }}
            submitForm={handleSubmit}
          >
            {!fetching && taskMenuItems && (
              <Form>
                <DropDownMUI
                  label="Dependency Relationship"
                  fieldName="type"
                  items={Object.values(TaskDependencyRelationalType).map((type) => ({
                    value: type,
                    label: titleCase(type.replaceAll("_", " ")),
                    isDefault: type === values.type,
                  }))}
                />
                <Box display="flex" alignItems="center" justifyContent="space-around">
                  <MuiGroupedSingleSelect
                    label="Predecessor Task"
                    name="predecessorTaskId"
                    groupedOptions={groupedTaskOptions}
                    disabled={!!dependency?.predecessorTaskId && dependency?.predecessorTaskId === taskId}
                    disabledOptionIds={values.dependentTaskId ? [values.dependentTaskId] : []}
                  />
                  <BiGitCompare size={60} className="flex m-3 text-gray-400" />
                  <MuiGroupedSingleSelect
                    label="Successor Task"
                    name="dependentTaskId"
                    groupedOptions={groupedTaskOptions}
                    disabled={!!dependency?.dependentTaskId && dependency?.dependentTaskId === taskId}
                    disabledOptionIds={values.predecessorTaskId ? [values.predecessorTaskId] : []}
                  />
                </Box>
                {values.predecessorTaskId && values.predecessorTaskId === values.dependentTaskId && (
                  <Typography className="text-red-500 mb-4" fontSize={12} fontWeight={400}>
                    A task cannot be dependent on itself
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="mt-4">
                    <Box className="mt-2 flex items-center space-x-2">
                      <SliderWithControls name="metadata.offsetDays" />
                      <NumberInputWithAdornment name="metadata.offsetDays" adornmentText="days" />
                    </Box>
                  </div>
                </Box>
              </Form>
            )}
          </MuiModal>
        )
      }}
    </Formik>
  )
}
