import { Box, LinearProgress, Typography } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import { FC, useMemo } from "react"
import { useQuery } from "urql"
import { TimeEntryActivityDocument } from "../../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../../graphql/generated/gql"
import { TaskOrProjectTeamTableQuery } from "../../../../graphql/generated/gql/graphql"
import { pluralize } from "../../../../helpers/strings/pluralize"
import { UserBadge } from "../../../UserBadge"
import { CustomDetailPanelToggleColDef } from "../../DataGrid/CustomDetailPanelToggle"
import { ProjectInjuryReports, TaskInjuryReports } from "../../InjuryReports/InjuryReports"
import { TimeEntryActivityBar } from "../../User/TimeEntryActivityBar"
import { TeamMemberDetailPanel, TeamSummaryQueryProps } from "../TeamTableDropDown"
import { TeamMemberDetailPanelDeprecated } from "../TeamTableDropDownDeprecated"
import { Mode, TimeCell, countWeeksInMonth, daysOfWeek } from "../TeamTableTimeCell"
import { SummarySectionProps } from "../types"
import { EmptyStateBlock } from "../../../Table/EmptyStateBlock"
import { addWeeks, endOfWeek, format, startOfWeek } from "date-fns"
import { TotalTimeWithEdits } from "../../User/TimeEntries/TotalTimeWithEdits"
import { DevelopmentFeatureFlag } from "../../../DevelopmentFeatureFlag"
import { HoursAndMinutes } from "../../../Time/HoursAndMinutes"
import { colors } from "../../../../helpers/styles/colors"

const SummaryTeamTableDocument = graphql(`
  query TaskOrProjectTeamTable(
    $projectId: String!
    $taskId: String
    $rangeEnd: DateTime!
    $rangeStart: DateTime!
    $rangeStartOn: String!
    $rangeEndOn: String!
  ) {
    usersForSummarySection(taskId: $taskId, projectId: $projectId, rangeEnd: $rangeEnd, rangeStart: $rangeStart) {
      id
      currentProjectId
      firstName
      imageUrl
      jobTitle
      lastName
      isClockedIn
      secondsClockedInDateRangeOnProjectOrTask(rangeEnd: $rangeEnd, rangeStart: $rangeStart, taskId: $taskId)
      totalEditedTimeInRange(rangeStartOn: $rangeStartOn, rangeEndOn: $rangeEndOn, projectId: $projectId)
      reportedUnitsInDateRangeByProjectOrTask(rangeEnd: $rangeEnd, rangeStart: $rangeStart, taskId: $taskId) {
        id
        unitGoalProgressReports {
          id
          progress
        }
      }
    }
  }
`)

export const defaultColumnProps: Partial<GridColDef> = {
  flex: 1,
  hideable: false,
  sortable: false,
  disableColumnMenu: true,
  filterable: false,
  resizable: false,
}
type UserExpectation = TaskOrProjectTeamTableQuery["usersForSummarySection"][0]

export const ProjectAndTaskSummaryTeamTable: FC<SummarySectionProps> = ({
  projectId,
  taskId,
  rangeEnd,
  rangeStart,
  dateRangeType = "daily",
}) => {
  const [{ data, fetching }] = useQuery<TaskOrProjectTeamTableQuery>({
    query: SummaryTeamTableDocument,
    variables: {
      taskId,
      projectId,
      rangeStart,
      rangeEnd,
      rangeStartOn: format(rangeStart, "yyyy-MM-dd"),
      rangeEndOn: format(rangeEnd, "yyyy-MM-dd"),
    },
    pause: !projectId,
  })

  const users: UserExpectation[] = useMemo(() => data?.usersForSummarySection ?? [], [data])

  const columns: GridColDef[] = [
    {
      field: "firstName",
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }: { row: UserExpectation }) => <UserBadge user={row} />,
      headerName: "Name",
    },
    {
      field: "durationInSeconds",
      headerName: "Total Time",
      minWidth: 150,
      flex: 1,
      renderCell: ({ row }: { row: UserExpectation }) => (
        <DevelopmentFeatureFlag
          name="Time Cards"
          fallbackComponent={<HoursAndMinutes seconds={row.secondsClockedInDateRangeOnProjectOrTask} />}
        >
          <TotalTimeWithEdits
            totalTimeInSeconds={row.secondsClockedInDateRangeOnProjectOrTask}
            totalEditedTimeInSeconds={row.totalEditedTimeInRange}
            showTimeDiff
            className="flex-col"
          />
        </DevelopmentFeatureFlag>
      ),
    },
  ]

  if (dateRangeType === "daily") {
    columns.splice(
      columns.findIndex((column) => column.field === "durationInSeconds"),
      1
    )

    columns.push(
      {
        field: "timeEntries",
        flex: 1,
        minWidth: 80,
        headerName: "Activity",
        renderCell: ({ row }: { row: UserExpectation }) => {
          return (
            <DeferredTimeEntryActivityBar
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              projectId={projectId}
              userId={row.id}
            />
          )
        },
      },
      {
        field: "reportedUnitsInDateRangeByProjectOrTask",
        headerName: "Reports",
        flex: 1,
        minWidth: 80,
        renderCell: ({ row }: { row: UserExpectation }) => {
          const { reportedUnitsInDateRangeByProjectOrTask: reports } = row
          if (reports.length === 0) return <div className="text-gray-400">None</div>
          return (
            <div>
              {reports.length} {pluralize("report", reports.length)}
            </div>
          )
        },
      },
      {
        field: "secondsClockedInDateRangeOnProjectOrTask",
        flex: 1,
        minWidth: 120,
        headerName: "Time",
        renderCell: ({ row }: { row: UserExpectation }) => (
          <DevelopmentFeatureFlag
            name="Time Cards"
            fallbackComponent={<HoursAndMinutes seconds={row.secondsClockedInDateRangeOnProjectOrTask} />}
          >
            <TotalTimeWithEdits
              totalTimeInSeconds={row.secondsClockedInDateRangeOnProjectOrTask}
              totalEditedTimeInSeconds={row.totalEditedTimeInRange}
              showTimeDiff
              className="flex-col"
            />
          </DevelopmentFeatureFlag>
        ),
      },
      { ...CustomDetailPanelToggleColDef }
    )
  }

  if (dateRangeType === "weekly") {
    daysOfWeek.forEach((day, index) => {
      columns.splice(index + 1, 0, {
        field: day,
        headerName: day,
        flex: 1,
        minWidth: 80,
        renderCell: ({ row }: { row: UserExpectation }) => (
          <TimeCell
            mode={Mode.Weekly}
            userId={row.id}
            day={day}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            projectId={projectId}
          />
        ),
      })
    })
  }
  if (dateRangeType === "monthly") {
    const numberOfWeeks = countWeeksInMonth(rangeStart, rangeEnd)
    const weekRanges: { [key: string]: string } = {}
    for (let i = 0; i < numberOfWeeks; i++) {
      const weekStart =
        startOfWeek(addWeeks(rangeStart, i)) < rangeStart ? rangeStart : startOfWeek(addWeeks(rangeStart, i))
      const weekEnd = endOfWeek(weekStart) > rangeEnd ? rangeEnd : endOfWeek(weekStart)
      weekRanges[`week${i + 1}`] = `${format(weekStart, "MMM d")} - ${format(weekEnd, "MMM d")}`
    }
    for (let i = 0; i < numberOfWeeks; i++) {
      const weekRange = weekRanges[`week${i + 1}`]
      columns.splice(i + 1, 0, {
        field: `week${i + 1}`,
        headerName: `Week ${i + 1}`,
        flex: 1,
        minWidth: 80,
        renderHeader: (params) => {
          return (
            <div className="flex flex-col">
              <Typography className="font-bold">{params.colDef.headerName}</Typography>
              <Typography className="text-xs">{weekRange}</Typography>
            </div>
          )
        },
        renderCell: ({ row }: { row: UserExpectation }) => (
          <TimeCell
            mode={Mode.Monthly}
            index={i}
            userId={row.id}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            projectId={projectId}
          />
        ),
      })
    }
  }

  const isEmpty = !users || users.length === 0

  return (
    <Box
      paddingY={3}
      paddingX={2}
      style={{
        border: `1px solid ${colors.slate[200]}`,
        borderRadius: "8px",
      }}
    >
      <div className="flex flex-col gap-y-4">
        <div>
          <Typography variant="h4">Team</Typography>
          <DataGridPro
            autoHeight
            loading={fetching && isEmpty}
            columns={columns.map((column) => ({ ...defaultColumnProps, ...column }))}
            disableRowSelectionOnClick
            getDetailPanelHeight={() => "auto"}
            getDetailPanelContent={
              dateRangeType === "daily"
                ? (params) => (
                    <DevelopmentFeatureFlag
                      name="Time Cards"
                      fallbackComponent={
                        <TeamMemberDetailPanelDeprecated
                          key={`${params.row.id}-panel-detail`}
                          userId={params.row.id}
                          rangeEnd={rangeEnd}
                          rangeStart={rangeStart}
                          projectId={projectId}
                        />
                      }
                    >
                      <TeamMemberDetailPanel
                        key={`${params.row.id}-panel-detail`}
                        userId={params.row.id}
                        rangeEnd={rangeEnd}
                        rangeStart={rangeStart}
                        projectId={projectId}
                      />
                    </DevelopmentFeatureFlag>
                  )
                : undefined
            }
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
            pageSizeOptions={[10]}
            pagination
            rows={users}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => <EmptyStateBlock label="No team" />,
            }}
          />
        </div>
        <Typography variant="h4">Injuries</Typography>
        {taskId ? <TaskInjuryReports taskId={taskId} /> : <ProjectInjuryReports projectId={projectId} />}
      </div>
    </Box>
  )
}

const DeferredTimeEntryActivityBar: FC<TeamSummaryQueryProps> = ({ rangeStart, rangeEnd, projectId, userId }) => {
  const [{ data }] = useQuery({
    query: TimeEntryActivityDocument,
    variables: { rangeStart, rangeEnd, projectId, userId },
    pause: !projectId,
  })

  return <TimeEntryActivityBar timeEntries={data?.user?.timeEntries ?? []} minDate={rangeStart} maxDate={rangeEnd} />
}
