import React, { FC } from "react"
import { Skeleton, Typography } from "@mui/material"
import { format } from "date-fns"
import { SectionHeader } from "../../../../PageSectionHeader"
import { Day } from "./TimeEntryDayDrawer"

type Photo = {
  src: string
  type: "clockIn" | "clockOut" | "signature"
  timestamp: Date | null | undefined
}

export const PhotosAndSignatures: FC<{ day: Day | undefined; loading: boolean }> = ({ day, loading }) => {
  const dayPhotos = (day?.timeEntryWrappers || []).flatMap((timeEntryWrapper) => {
    const { evidenceType, startAt, endAt } = timeEntryWrapper.timeEntry

    const signInPhotoUrl = evidenceType.start?.imageUrl
    const signOutPhotoUrl = evidenceType.end?.imageUrl
    const signaturePhotoUrl = evidenceType.end?.signatureUrl

    const photos: Photo[] = []

    if (signInPhotoUrl)
      photos.push({
        src: signInPhotoUrl,
        type: "clockIn",
        timestamp: startAt,
      })
    if (signOutPhotoUrl)
      photos.push({
        src: signOutPhotoUrl,
        type: "clockOut",
        timestamp: endAt,
      })

    if (signaturePhotoUrl)
      photos.push({
        src: signaturePhotoUrl,
        type: "signature",
        timestamp: endAt,
      })

    return photos
  })

  if (loading)
    return (
      <div className="mb-8">
        <SectionHeader title="Photos & Signatures" />
        <div className="flex flex-wrap gap-4">
          <Skeleton variant="rectangular" width={224} height={224} />
          <Skeleton variant="rectangular" width={224} height={224} />
          <Skeleton variant="rectangular" width={224} height={224} />
        </div>
      </div>
    )

  return (
    <div className="mb-8">
      <SectionHeader title="Photos & Signatures" />
      {!dayPhotos.length ? (
        <EmptySectionBody label="No photos" />
      ) : (
        <div className="flex flex-wrap gap-4">
          {dayPhotos.map((photo) => {
            return (
              <div key={photo.src}>
                <img className="border rounded size-56" src={photo.src} alt="Time Entry photo" />
                <PhotoLabel photo={photo} />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

const PhotoLabel: FC<{ photo: Photo }> = ({ photo }) => {
  let header = ""
  if (photo.type == "clockIn") header = "Clock in"
  if (photo.type == "clockOut") header = "Clock out"
  if (photo.type == "signature") header = "Signature"

  const timestamp = photo.timestamp ? format(photo.timestamp, "M/d/yyyy h:m a") : "No timestamp"
  return (
    <Typography>
      {header} {timestamp}
    </Typography>
  )
}

const EmptySectionBody: FC<{ label: string }> = ({ label }) => {
  return <div className="text-center text-gray-400 border rounded py-5">{label}</div>
}
