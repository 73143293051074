import { Button } from "@mui/material"
import { clsx } from "clsx"
import { NextPage } from "next"
import Error from "next/error"
import { useRouter } from "next/router"
import { FC, MutableRefObject, useMemo, useState } from "react"
import { BiStopwatch } from "react-icons/bi"
import { useQuery } from "urql"
import { DevelopmentFeatureFlag } from "../../../components/DevelopmentFeatureFlag"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { PageTitle } from "../../../components/PageTitle"
import { UserInjuryReports } from "../../../components/Partials/InjuryReports/InjuryReports"
import { CreateTimeEntryForm } from "../../../components/Partials/User/CreateTimeEntryForm"
import {
  EditDayActivityForm,
  TimeEntryWithProjectAndTaskName,
} from "../../../components/Partials/User/EditDayActivityForm/EditDayActivityForm"
import { TimeEntryDaysDataGrid } from "../../../components/Partials/User/TimeEntries/DataGrid/TimeEntryDaysDataGrid"
import { DeprecatedTimeEntryDataGrid } from "../../../components/Partials/User/TimeEntries/DeprecatedDataGrid/DeprecatedTimeEntryDataGrid"
import { RenderIf } from "../../../components/RenderIf"
import { NotificationsTable } from "../../../components/UserNotifications/NotificationTable"
import { H4 } from "../../../components/deprecated"
import DeprecatedModal from "../../../components/deprecated/StandardModal"
import { UserNotificationModelType } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { getFullName } from "../../../helpers/strings/getFullName"
import UserPageLayout from "./_layout"

const UserTimeCardsPageDocument = graphql(`
  query UserTimeCardsPage($userId: String!) {
    user(id: $userId) {
      id
      firstName
      lastName
      currentProjectId
      archived
      project {
        id
        name
      }
      currentTaskId
      task {
        id
        name
      }
      timeEntries {
        id
        durationInSeconds
        endAt
        signInPhotoUrl
        signOutPhotoUrl
        projectId
        taskId
        startAt
        isUnpaid

        project {
          id
          name
          isDefault
        }
        task {
          id
          name
          visibilityLevel
        }
      }
    }
  }
`)

export const UserPageTimeCards: FC<{ userId: string; drawerRef?: MutableRefObject<null> }> = ({ userId }) => {
  const [{ data }] = useQuery({ query: UserTimeCardsPageDocument, variables: { userId } })

  const [selectedTimeEntries, setSelectedTimeEntries] = useState<TimeEntryWithProjectAndTaskName[]>([])
  const addTimeEntryFormModalProps = useModalProps("Add New Time Entry")
  const editDayActivityFormModalProps = useModalProps("Edit day activity")

  const timeEntries: TimeEntryWithProjectAndTaskName[] = useMemo(() => {
    return (data?.user?.timeEntries || []).map((te) => ({
      ...te,
      isBreak: te.task.visibilityLevel === "HIDDEN",
    }))
  }, [data?.user?.timeEntries])

  const user = data?.user

  return (
    <>
      <PageTitle title={!user?.firstName && !user?.lastName ? "User time cards" : `${getFullName(user)} time cards`} />
      <NotificationsTable modelType={UserNotificationModelType.User} userId={userId} />
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mb-4">
        <H4 className="mb-2 md:col-span-2 col-span-1">Time Card History</H4>
        <div className="pb-3 grid justify-start md:justify-end">
          <RenderIf permissionsInclude="timeEntry:create" context={{ projectId: user?.currentProjectId }}>
            <Button
              variant="contained"
              color="secondary"
              className={clsx(user?.archived && "opacity-40")}
              endIcon={<BiStopwatch className="size-5" />}
              disabled={user?.archived}
              onClick={addTimeEntryFormModalProps.handleOpenModal}
            >
              Add time entry
            </Button>
          </RenderIf>
        </div>
      </div>
      <div>
        <UserInjuryReports userId={userId} />
      </div>
      <div style={{ width: "100%", marginTop: 30 }}>
        <DevelopmentFeatureFlag
          name="Time Cards"
          fallbackComponent={
            <DeprecatedTimeEntryDataGrid
              userId={userId}
              openEditDayActivityModal={(newSelectedTimeEntries) => {
                setSelectedTimeEntries(newSelectedTimeEntries)
                editDayActivityFormModalProps.handleOpenModal()
              }}
            />
          }
        >
          <TimeEntryDaysDataGrid userId={userId} />
        </DevelopmentFeatureFlag>
      </div>
      {user && addTimeEntryFormModalProps.isOpen && (
        <DeprecatedModal {...addTimeEntryFormModalProps} className="overflow-y-visible">
          <CreateTimeEntryForm
            user={user}
            onCancel={addTimeEntryFormModalProps.handleCloseModal}
            onSuccess={() => {
              addTimeEntryFormModalProps.handleCloseModal()
            }}
            timeEntries={timeEntries}
          />
        </DeprecatedModal>
      )}
      {user && editDayActivityFormModalProps.isOpen && (
        <DeprecatedModal {...editDayActivityFormModalProps}>
          <EditDayActivityForm
            onCancel={editDayActivityFormModalProps.handleCloseModal}
            onSuccess={editDayActivityFormModalProps.handleCloseModal}
            timeEntries={selectedTimeEntries}
            user={user}
          />
        </DeprecatedModal>
      )}
    </>
  )
}

const UserPage: NextPage = () => {
  const { query } = useRouter()
  const userId = query._id as string

  return (
    <RenderIf permissionsInclude="user:read" context={{ userId }} fallbackComponent={<Error statusCode={404} />}>
      <UserPageLayout userId={userId}>
        <UserPageTimeCards userId={userId} />
      </UserPageLayout>
    </RenderIf>
  )
}

export default UserPage
