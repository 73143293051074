import { Typography } from "@mui/material"
import { NextPage } from "next"
import Error from "next/error"
import { useRouter } from "next/router"
import { FC, useState } from "react"
import { useQuery } from "urql"
import { DateSelect } from "../../../../components/DateSelect"
import { PageTitle } from "../../../../components/PageTitle"
import { OrganizationSkeleton } from "../../../../components/Partials/Organizations/Organization.skeleton"
import { FilterGroupWithQueryStringParam } from "../../../../components/Partials/Summary/FilterGroupWithQueryStringParam"
import { SummaryImageSection } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryImagesSection"
import { SummaryNotesSection as TaskSummaryNotesSection } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryNotesSection"
import { ProjectAndTaskSummaryTeamTable } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryTeamTable"
import { SummaryTimeline as TaskTimeline } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryTimeline"
import { SummaryUnitsGraphAndTable } from "../../../../components/Partials/Summary/Sections/ProjectAndTaskSummaryUnitsGraphAndTable"
import { DateRangeFilterString, SummaryFilterType } from "../../../../components/Partials/Summary/types"
import { RenderIf } from "../../../../components/RenderIf"
import { TaskOverviewWidgets } from "../../../../components/Widgets/SummaryWidgets/TaskOverviewWidgets"
import { TaskSummaryQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { NormalizedDateRange, useDateRange } from "../../../../hooks/useMuiDateRange"
import TaskLayout, { TaskPageTitle } from "./_layout"
import { addDays, endOfDay, startOfDay } from "date-fns"
import { graphql } from "../../../../graphql/generated/gql"
import { BiCalendar, BiCar, BiGridAlt, BiGroup, BiImage, BiNote, BiRuler } from "react-icons/bi"

export const TaskSummaryDocument = graphql(`
  query TaskSummary($id: String!) {
    task(id: $id) {
      id
      projectId
      groupId
      group {
        id
        name
      }
      organizationId
      archived
      createdAt
      description
      startDate
      endDate
      estimatedHours
      completedHours
      hasReportableUnit
      isComplete
      isDefault
      metadata {
        label
        content
      }
      name
      assetCount
      userCount
      workersCompCode {
        id
        name
        ncciCode
      }
      assignees {
        id
        isCurrentAssignment
        projectId
        taskId
        userId
        project {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
      unitGoals {
        id
        deliverableUnitId
        targetQuantity
        isPrimary
        deliverableUnit {
          id
          description
          referenceNumber
          unitOfMeasure
        }
        unitGoalProgress {
          id
          progress
        }
      }
      project {
        id
        isArchived
        name
        userCount
        assetsCount
      }
    }
  }
`)

type TaskSummaryPageProps = {
  task: TaskSummaryQuery["task"]
}

export const TaskSummaryPageContent: FC<TaskSummaryPageProps> = ({ task }) => {
  const [dateRangeType, setDateRangeType] = useState<DateRangeFilterString>("daily")

  const [dateRange, setDateRange] = useDateRange({ defaultRangeType: dateRangeType })
  const { rangeStart, rangeEnd } = dateRange

  const [graphDateRange, setGraphDateRange] = useState<NormalizedDateRange>({
    rangeStart: startOfDay(addDays(new Date(), -20)),
    rangeEnd: endOfDay(new Date()),
  })
  const { rangeStart: graphRangeStart, rangeEnd: graphRangeEnd } = graphDateRange

  const [filter, setFilter] = useState<SummaryFilterType>("all")

  const summaryFilterOptions = [
    { title: "All", value: "all", icon: <BiGridAlt /> },
    { title: "Units", value: "units", icon: <BiRuler /> },
    { title: "Team", value: "team", icon: <BiGroup /> },
    { title: "Assets", value: "assets", icon: <BiCar /> },
    { title: "Images", value: "images", icon: <BiImage /> },
    { title: "Notes", value: "notes", icon: <BiNote /> },
    { title: "Timeline", value: "timeline", icon: <BiCalendar /> },
  ]

  const handleSetFilter = (newFilter: unknown) => {
    setFilter(newFilter as SummaryFilterType)
  }

  const projectId = task?.projectId

  return (
    projectId && (
      <>
        <PageTitle title={`${task?.name || "Task"} summary`} />
        <header className="flex flex-col gap-y-5 pb-10">
          <Typography variant="h3">Task Summary</Typography>
          <DateSelect
            startDate={task.startDate ? task.startDate : task.createdAt}
            dateRangeType={dateRangeType}
            onChange={(newDate: NormalizedDateRange) => setDateRange(newDate)}
            setFilter={setDateRangeType}
          />

          <TaskOverviewWidgets taskId={task.id} rangeStart={rangeStart} rangeEnd={rangeEnd} />

          <FilterGroupWithQueryStringParam
            defaultFilterValue="all"
            filter={filter}
            options={summaryFilterOptions}
            queryStringParameter="filter"
            setFilter={handleSetFilter}
          />
        </header>
        <main className="flex flex-col gap-y-10 pb-28">
          {(filter === "all" || filter === "units") && (
            <SummaryUnitsGraphAndTable
              entityType="task"
              entityId={task.id}
              dateRangeType={dateRangeType}
              graphRangeEnd={graphRangeEnd}
              graphRangeStart={graphRangeStart}
              rangeEnd={rangeEnd}
              rangeStart={rangeStart}
              setGraphDateRange={setGraphDateRange}
              minDate={task.startDate ?? task.createdAt}
              isTaskDrawer
            />
          )}

          {projectId && (filter === "all" || filter === "team") && (
            <ProjectAndTaskSummaryTeamTable
              taskId={task.id}
              projectId={projectId}
              rangeEnd={rangeEnd}
              rangeStart={rangeStart}
              dateRangeType={dateRangeType}
            />
          )}

          {(filter === "all" || filter === "images") && (
            <SummaryImageSection
              taskId={task.id}
              projectId={projectId}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              dateRangeType={dateRangeType}
              filter={filter}
            />
          )}
          {(filter === "all" || filter === "notes") && (
            <TaskSummaryNotesSection
              taskId={task.id}
              projectId={projectId}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              dateRangeType={dateRangeType}
              filter={filter}
            />
          )}

          {(filter === "all" || filter === "timeline") && (
            <TaskTimeline
              taskId={task.id}
              projectId={projectId}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              filter={filter}
            />
          )}
        </main>
      </>
    )
  )
}

const TaskSummaryPage: NextPage = () => {
  const { query } = useRouter()
  const taskId = query._task_id as string

  const [{ data, error }] = useQuery({
    query: TaskSummaryDocument,
    variables: { id: taskId },
  })

  return (
    <RenderIf permissionsInclude="task:read" context={{ taskId }} fallbackComponent={<Error statusCode={404} />}>
      <TaskLayout taskId={taskId}>
        <TaskPageTitle />

        {!data && <OrganizationSkeleton />}
        {error && <Error statusCode={400} />}
        {data?.task && <TaskSummaryPageContent task={data.task} />}
      </TaskLayout>
    </RenderIf>
  )
}

export default TaskSummaryPage
